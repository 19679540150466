import { useAuthStore } from "@/store/authStore";
import { ComputedRef, computed, onMounted, onUnmounted, ref } from "vue";
import useConsoleLogger from "./useConsoleLogger";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from "@microsoft/signalr";
import { DepthRowUpdate, DepthSnapshot, DepthUpdate } from "@/models/depth";
import { useDepthStore } from "@/store/depth";
import { useSystemSocketStore } from "@/store/systemSocket";
import { SocketResponse } from "@/models/trading";
import { useToastStore } from "@/store/toastStore";
import { useMarketDisplayStore } from "@/store/marketDisplay";

export type DepthStoreType = ReturnType<typeof useDepthStore>;

export const useDepthConversions = () => {
  function createTypedDepthUpdate(data: Record<string, unknown>): DepthUpdate {
    const typedObject: Partial<DepthUpdate> = {};

    for (const key in data) {
      const camelKey = pascalToCamel(key);
      const value = data[key];

      switch (camelKey) {
        case "displaySeq":
          typedObject.displaySeq = value as number;
          break;
        case "depthUpdateList":
          typedObject.depthUpdateList = (
            value as Record<string, unknown>[]
          ).map(createTypedDepthRowUpdate);
          break;
        default:
          useConsoleLogger.warn(
            `Unexpected key "${camelKey}" with value ${value}`
          );
      }
    }

    return typedObject as DepthUpdate;
  }

  function createTypedDepthRowUpdate(
    data: Record<string, unknown>
  ): DepthRowUpdate {
    const typedObject: Partial<DepthRowUpdate> = {};
    for (const key in data) {
      const camelKey = pascalToCamel(key);
      const value = data[key];

      switch (camelKey) {
        case "updateType":
          typedObject.updateType = value as number;
          break;
        case "updateValue":
          typedObject.updateValue = value as number;
          break;
        case "rowNumber":
          typedObject.rowNumber = value as number;
          break;
        default:
          useConsoleLogger.warn(
            `Unexpected key "${camelKey}" with value ${value}`
          );
      }
    }

    return typedObject as DepthRowUpdate;
  }
  function pascalToCamel(str: string): string {
    if (str.toLowerCase() === "contract_type") {
      return "CONTRACT_TYPE";
    }

    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  return {
    pascalToCamel,
    createTypedDepthRowUpdate,
    createTypedDepthUpdate,
  };
};

export const useDepthWebsocket = (depthContractName: string) => {
  const authStore = useAuthStore();
  const systemSocketStore = useSystemSocketStore();
  const socket = computed(
    () => systemSocketStore.depthSocket as HubConnection | null
  );

  const storeInstance: DepthStoreType = useDepthStore(depthContractName);

  const onConnect = async () => {
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 2000);
    });
    if (socket) {
      const res = await socket.value?.invoke<SocketResponse>(
        "SubscribeToGroups"
      );
      if (storeInstance().getID.value) {
        const marketStore = useMarketDisplayStore();
        const found = marketStore().getData.value.find(
          (e) => e.contract == storeInstance().getID.value
        );
        useConsoleLogger.log("Depth store id ", storeInstance().getID.value);
        const res = await socket.value?.invoke<SocketResponse>(
          "SubscribeDepth",
          [found?.displaySeq]
        );
        useConsoleLogger.log("DEPTH SOCKET: Depth sub -", res);
      } else {
        useConsoleLogger.error("DEPTH SOCKET: No store id --");
      }

      useConsoleLogger.log(
        "Connected to depth ",
        storeInstance().getRows.value
      );
    }
  };
  async function connect() {
    await systemSocketStore.initializeSocket(onConnect, [], "depth");
  }
  async function disconnect() {
    await systemSocketStore.closeSocket("depth");
  }
  return { connect, disconnect, storeInstance, socket };
};
