import { formatNumber } from "@/main";
import { BreakdownAnalysis } from "@/models/breakdownAnalysis";
import { Clearing } from "@/models/clearingInfo";
import {
  ActiveOrder,
  MarketDisplayItemContract,
  MtmViewModel,
  MarketDisplayItemConsolidatedPositionUnique,
  MarketDisplayItemPosition,
  Deal,
  UnMatchedDeal,
  CompletedOrder,
  MtmPriceViewModel,
} from "@/models/marketData";
import {
  SiloCert,
  SiloCertSpotDeal,
  SiloPosition,
  SiloSpotBasisActiveOrder,
  SiloSpotCompletedOrder,
  SiloSpotContractViewModel,
  SiloSpotUnmatchedDeal,
} from "@/models/silos";
import { GroupNode } from "@/utils/useWebsocket";
export function isModelType<T>(
  item: unknown,
  typeGuard: (item: unknown) => item is T
): item is T {
  return typeGuard(item);
}
export function isMarketDisplayItemContract(
  item: unknown
): item is MarketDisplayItemContract {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isMarketDisplayItemContract(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "displaySeq" in (item as any);
  }
}
export function isCompletedOrder(item: unknown): item is CompletedOrder {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isCompletedOrder(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "completedOrderSeq" in (item as any);
  }
}
export function isActiveOrder(item: unknown): item is ActiveOrder {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isActiveOrder(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "activeOrderSeq" in (item as any);
  }
}
export function isMTMPrice(item: unknown): item is MtmPriceViewModel {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isMTMPrice(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (
      "instrument" in (item as any) &&
      "contractDate" in (item as any) &&
      "oi" in (item as any)
    );
  }
  // return (item as MtmViewModel).spotPrice !== undefined;
}
export function isMTM(item: unknown): item is MtmViewModel {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isMTM(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "spotPrice" in (item as any);
  }
  // return (item as MtmViewModel).spotPrice !== undefined;
}
export function isConsPos(
  item: unknown
): item is MarketDisplayItemConsolidatedPositionUnique {
  // return (
  //   (item as MarketDisplayItemConsolidatedPositionUnique).uniqueKey !==
  //   undefined
  // );
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isConsPos(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "uniqueKey" in (item as any);
  }
}
export function isPos(item: unknown): item is MarketDisplayItemPosition {
  // return (item as MarketDisplayItemPosition).positionSeq !== undefined;
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isPos(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "positionSeq" in (item as any);
  }
}
export function isDeal(item: unknown): item is Deal {
  // return (item as Deal).dealSeq !== undefined;
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isDeal(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "dealSeq" in (item as any);
  }
}
export function isUnMatchedDeal(item: unknown): item is UnMatchedDeal {
  // return (item as UnMatchedDeal).unmatchedSeq !== undefined;
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isUnMatchedDeal(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "unmatchedSeq" in (item as any);
  }
}
export function isBreakdown(item: unknown): item is BreakdownAnalysis {
  // return (item as BreakdownAnalysis).m !== undefined;

  // if ((item as GroupNode<BreakdownAnalysis>).items) {
  //   return (item as GroupNode<BreakdownAnalysis>).items.some(
  //     (subItem) => "m" in (subItem as any)
  //   );
  // } else {
  //   return "m" in (item as any);
  // }
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isBreakdown(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return "m" in (item as any);
  }
}
export function isClearing(item: unknown): item is Clearing {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isClearing(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (
      (item as Clearing).p !== undefined && (item as Clearing).brf !== undefined
    );
  }
}
export function isSB_Contract(
  item: unknown
): item is SiloSpotContractViewModel {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_Contract(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (
      (item as SiloSpotContractViewModel).instrument !== undefined &&
      (item as SiloSpotContractViewModel).location !== undefined
    );
  }
}
export function isSB_ActiveSuspendedOrder(
  item: unknown
): item is SiloSpotBasisActiveOrder {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_ActiveSuspendedOrder(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (
      (item as SiloSpotBasisActiveOrder).seq !== undefined &&
      (item as SiloSpotBasisActiveOrder).state !== undefined
    );
  }
}
export function isSB_Deal(item: unknown): item is SiloCertSpotDeal {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_Deal(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (
      (item as SiloCertSpotDeal).seq !== undefined &&
      (item as SiloCertSpotDeal).siloReceiptNo !== undefined &&
      (item as SiloCertSpotDeal).dealtPremium !== undefined
    );
  }
}
export function isSB_UnmacthedDeal(
  item: unknown
): item is SiloSpotUnmatchedDeal {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_UnmacthedDeal(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (
      (item as SiloSpotUnmatchedDeal).unmatchedSeq !== undefined &&
      (item as SiloSpotUnmatchedDeal).siloReceiptNo !== undefined
    );
  }
}
export function isSB_SiloCert(item: unknown): item is SiloCert {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_SiloCert(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (item as SiloCert).siloCertSeq !== undefined;
  }
}
export function isSB_SiloCompleted(
  item: unknown
): item is SiloSpotCompletedOrder {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_SiloCompleted(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (item as SiloSpotCompletedOrder).seq !== undefined;
  }
}

export function isSB_SiloPosition(
  item: unknown
): item is SiloPosition {
  if (isGroupNode(item)) {
    for (const subItem of item.items) {
      if (isSB_SiloPosition(subItem)) {
        return true;
      }
    }
    return false;
  } else {
    return (item as SiloPosition).unique !== undefined;
  }
}

export function isGroupNode(
  item: GroupNode<any> | any
): item is GroupNode<any> {
  return item && "items" in item && item.key !== null && item.value !== null;
}


export function getNestedValue(
  item: any,
  key: string,
  fixed: boolean = true,
  ignoreFormat: boolean = false
): string | number | undefined {
  if (typeof item !== "object" || item === null) {
    return undefined;
  }

  const keys = key.split(".");
  let result: any = item;

  for (let i = 0; i < keys.length; i++) {
    if (isGroupNode(result)) {
      result = result.items[0]; // We're assuming that items array is not empty
    }

    if (result[keys[i]] === undefined) {
      return undefined;
    } else {
      const temp = result[keys[i]];
      if (typeof temp == "number" && ignoreFormat == false) {
        result = formatNumber(temp, fixed);
      } else {
        result = temp;
      }
    }
  }

  return result;
}
