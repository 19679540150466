import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { createBaseStore } from "./baseStore";
import { DailyTrend as MainModel} from "@/models/marketData";

export const useTradeHistory = defineStore("tradeHistory", () => {
  const data: Ref<MainModel[]> = ref([]);

  function updateItem(item: MainModel) {
    if (data.value.find((e) => e == item) == null) {
      data.value.push(item);
    }

  }

  return {
    data,
    updateItem
  };
});

// export const useTradeHistory = createBaseStore<MainModel, {}>("TradeHistory", "contract");
