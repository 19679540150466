<template>
  <v-app id="app">
    <ToastHandler></ToastHandler>
    <router-view />
    <ReloadPrompt />
    <InstallPrompt />
    <OfflineHandler />
  </v-app>
</template>

<script setup lang="ts">
import { useAuthStore } from "@/store/authStore";
import { useToastStore } from "@/store/toastStore";
import { onMounted, onUnmounted, Ref, ref } from "vue";
import ToastHandler from "@/components/ToastHandler.vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { computed, watch } from "vue";
import { useLayoutStore } from "./store/layout";
import Moveable from "@/utils/moveable.vue";
import ReloadPrompt from "@/components/PWA/ReloadPrompt.vue";
import MyWorker from "./my-worker?worker";
import InstallPrompt from "@/components/PWA/InstallPrompt.vue";
import OfflineHandler from "@/components/PWA/OfflineHandler.vue";
import { useAppStore } from "./store/app";

const authStore = useAuthStore();
const appStore = useAppStore();
const layoutStore = useLayoutStore();
const toastStore = useToastStore();
let intervalId: Ref<NodeJS.Timer | null> = ref(null);

// const isMobile = computed(() => {
//   return vuetifyHook.smAndDown.value
// })
// watch(isMobile, (val) => {
//   layoutStore.setMobileView(val)
// })

onMounted(() => {
  const token = localStorage.getItem("jwt");

  if (token) {
    authStore.setToken(token);
  }
  if (authStore.token && authStore.isTokenExpired == true){
      authStore.logout();
  }
  setTimeout(() => {
    intervalId.value = setInterval(() => {
    if (authStore.token && authStore.isTokenExpired == true){
      authStore.logout();
      toastStore.addToast("warning", "Token Expired");
    }
    console.log("Checking token expiry")
  }, 120000);
  }, 30000)
  
});
onUnmounted(() => {
  if (intervalId.value) clearInterval(intervalId.value);
});
</script>

<style>
#app {
  background: var(--v-background-base);
}
#app .v-table--density-compact > .v-table__wrapper > table > tbody > tr > td,
.v-table--density-compact > .v-table__wrapper > table > thead > tr > td,
.v-table--density-compact > .v-table__wrapper > table > tfoot > tr > td {
  height: calc(var(--v-table-row-height, 20px) - 25px) !important;
}
.v-selection-control--density-compact {
  --v-selection-control-size: 22px !important;
}
.table-item {
  min-width: 30px;
  max-width: 200px;
  white-space: nowrap;
}
.v-table__wrapper {
  border-radius: inherit;
  overflow-x: hidden !important;
  flex: 1 1 auto;
}
</style>
