<template>
  <v-snackbar
    location="right bottom"
    v-if="toastStore.currentToast"
    :key="toastStore.currentToast.id"
    max-width="60vw"
    :min-width="250"
    :model-value="toastStore.currentToast.showing"
    :color="toastStore.currentToast.type"
    :timeout="
      toastStore.currentToast.type == 'error'
        ? -1
        : toastStore.currentToast.type == 'warning'
        ? 5000
        : 3000
    "
    @update:modelValue="toastStore.removeToast(toastStore.currentToast.id)"
  >
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="toastStore.removeToast(toastStore.currentToast.id)"
      >
        Close
      </v-btn>
    </template>
    <div class="text-break">{{ toastStore.currentToast.message }}</div>
  </v-snackbar>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useToastStore } from "@/store/toastStore";

const toastStore = useToastStore();

const toasts = computed(() => toastStore.getToasts);
</script>
