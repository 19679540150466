<template>
  <v-snackbar
    max-width="400"
    timeout="-1"
    :z-index="10000"
    location="top right"
    :model-value="needRefresh"
    color="white"
    content-class="pwa-refresh-toast"
    vertical
  >
    <!-- :model-value="offlineReady || needRefresh"  -->
    <!-- <div class="text-subtitle-1 pb-2">This is a snackbar message</div>

      <p>This is a longer paragraph explaining something</p> -->
    <div class="text-subtitle-1 pb-2">
      <strong>New features and improvements</strong> are available!
    </div>
    <div class="mt-1 text-title-1 mb-2">
      <strong>Update</strong> now for a smoother and more efficient trading
      experience.
    </div>
    <div class="text-subtitle-2">
      <div class="font-weight-bold">* Save your layout, page will reload.</div>
    </div>

    <template v-slot:actions>
      <v-btn @click="close" color="primary" variant="outlined" class="mr-2">
        Close
      </v-btn>
      <v-btn
        v-if="needRefresh"
        @click="updateWorker"
        color="primary"
        variant="flat"
      >
        Update
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";
import { pwaInfo } from "virtual:pwa-info";
import useConsoleLogger from "@/utils/useConsoleLogger";

useConsoleLogger.log("PWA OPTIONS: ", pwaInfo);
const reloadSW: "true" | "false" = import.meta.env.VITE_RELOAD_SW;
const reloadPeriod = Number(import.meta.env.VITE_RELOAD_SW_PERIOD);
const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    useConsoleLogger.log(`Service Worker at: ${swUrl}`, reloadSW, reloadPeriod);
    if (reloadSW === "true") {
      r &&
        setInterval(async () => {
          // eslint-disable-next-line no-console
          console.log("Checking for sw update");
          await r.update();
        }, reloadPeriod);
    } else {
      useConsoleLogger.log(`SW Registered: ${r}`);
    }
  },
});

async function close() {
  offlineReady.value = false;
  needRefresh.value = false;
}
const updateWorker = async () => {
  try {
    await updateServiceWorker(true);
    window.location.reload();
  } catch (err) {
    console.error(err);
  }

  // await close();
};
</script>

<style>
.pwa-refresh-toast {
  border: 2px solid rgb(var(--v-theme-primary));
}
</style>
