export enum PublishAll {
  ActiveOrders = 1,
  Instruments = 2,
  Holidays = 3,
  ContractDate = 4,
  Positions = 5,
  CompletedOrders = 6,
  Deals = 7,
  UnmatchedDeal = 8,
  MTM = 9,
  ConsPositions = 10,
  Prices = 11,
  DailyTrends = 12,
  EarlyValuations = 13,
  BreakDownAnalysis = 14,
  Margins = 15,
  BfProfiles = 16,
  Movements = 17,
  ClearingInfo = 18,
  PreMarketOrders = 19,
  Clearing = 20,
  SiloCerts = 21,
  SiloContracts = 22,
  SiloDeals = 23,
  SiloActiveOrders = 24,
  SiloUnmatchedDeals = 25,
  SiloCompletedOrders = 26,
  SiloPositions = 27,
}
export type FilterCondition = {
  field: string;
  operator: "==" | "!==" | ">" | "<" | ">=" | "<=" | "contains";
  value: any;
};
export interface ContractDisplay {
  instrument: string;
  contractDate: string;
  strike: number;
  flag: string;
  CONTRACT_TYPE: number;
  contractDisplay: string;
}

export interface MarketDisplayItemContract {
  [key: string]: string | number | ContractDisplay;
  displaySeq: number;
  contract: string;
  contractDisplay: ContractDisplay;
  qtyBid: string;
  bid: number;
  offer: number;
  qtyOffer: string;
  change: string;
  time: string;
  last: number;
  hi: string;
  lo: string;
  volume: string;
  openPrice: number;
  contractSeq: string;
  dateSeq: number;
  strikeSeq: number;
  secondContractSeq: number;
  secondDateSeq: string;
  openInterest: number;
}

export interface MarketDisplayItemInstrument {
  instrumentSeq: number;
  instrumentType: number;
  instrumentGroup: number;
  futureFee: number;
  optionFee: number;
  deliveryFee: number;
  marketNumber: number;
  instrumentName: string;
  class: number;
  isIn: string;
  description: string;
  onScreen: boolean;
  issueDate: Date;
  underlying: number;
  optionExerciseIsPercentage: boolean;
  optionExerciseCost: number;
  groupMargin: number;
  vat: boolean;
  settlementMargin: number;
  physicallSettlement: boolean;
  groupDescription: string;
}
export interface Deal {
  [key: string]: string | null | number | Date | ContractDisplay;
  dealSeq: number;
  orderSeq: number;
  dealTime: string; // Using string to represent TimeSpan since there's no direct equivalent in TypeScript
  clearingMember: string;
  member: string;
  dealer: string;
  principal: string;
  buySell: string;
  origin: string;
  quantity: number;
  contract: string;
  contractDisplay: ContractDisplay;
  dealtRate: number;
  userRef: string;
  xtraUserRef: string;
  exchangeSuffix: number;
  portfolio: string;
  profitCentre: string | null;
  subAccount: string;
  exchangeRef: string;
  dealtPrice: number;
  dealConsideration: number;
  settlementDate: Date;
  bookingFeeFlag: string;
  reason: string;
  markToMarket: number;
  fee: number;
  commited: string;
  settled: string;
  clearingMemberFees: number;
  memberFees: number;
  principleAgency: string;
  feeDelta: number;
  makerQty: number;
  reportOnlyQty: number;
}

export interface UnMatchedDeal {
  [key: string]: string | number | ContractDisplay;
  assignSeq: number;
  bookingFeeFlag: string;
  buySell: string;
  clearingMember: string;
  contract: string;
  contractDisplay: ContractDisplay;
  counterParty: string;
  dealPrice: number;
  dealsDealer: string;
  dealsMember: string;
  dealsPrinciple: string;
  enterDate: string;
  enterTime: string;
  futurePrice: number;
  messageSeq: number;
  origin: string;
  portfolio: string;
  positionSeqToRollForward: number;
  priceRef: number;
  principleAgency: string;
  profitCentre: string;
  qty: number;
  rate: number;
  reason: string;
  rollForwardPrice: number;
  subAccount: string;
  suffixCode: number;
  tradeDate: string;
  tradeTime: string;
  unmatchedSeq: number;
  userDealer: string;
  userMember: string;
  userRef: string;
  xtraUserRef: string;
}

export interface MarketDisplayItemPosition {
  [key: string]: string | number | Date | ContractDisplay;
  positionSeq: number;
  clearingMember: string;
  member: string;
  dealer: string;
  principal: string;
  contract: string;
  contractDisplay: ContractDisplay;
  open: number;
  bought: number;
  sold: number;
  close: number;
  uncommitedBuy: number;
  uncommitedSell: number;
  uncommitedPosition: number;
  openNetUncommited: number;
  openTotalPosition: number;
  openConsideration: number;
  netUncommited: number;
  totalPosition: number;
  consideration: number;
  physicalPosition: number;
  physicalDeliveries: number;
  date: Date;
}
export interface MarketDisplayItemConsolidatedPositionUnique {
  [key: string]: string | number | Date | ContractDisplay;
  bought: number;
  close: number;
  contract: string;
  contractDisplay: ContractDisplay;
  date: Date;
  deliveries: number;
  member: string;
  open: number;
  phyPosition: number;
  principle: string;
  sold: number;
  uniqueKey: string;
}
export interface MarketDisplayItemConsolidatedPosition {
  bought: number;
  close: number;
  contract: string;
  contractDisplay: ContractDisplay;
  date: Date;
  deliveries: number;
  member: string;
  open: number;
  phyPosition: number;
  principle: string;
  sold: number;
  uniqueKey: string;
}
export interface MarketDisplayItemContractDate {
  instrumentSeq: number;
  contractDateSeq: number;
  expiryDate: Date;
  expiryMonths: number;
  valuationDate: Date;
  nominal: number;
  strikeInterval: number;
  strikeIntervalOffScreen: number;
  spreadMargin: number;
  lotSize: number;
  optionLotSize: number;
  bigDepth: number;
  priceRate: string;
  maxChange: number;
  maxDaysMove: number;
  maxGap: string;
  optionsAllowed: boolean;
  deltasAllowed: boolean;
  spreadsAllowed: boolean;
  initialMargin: number;
  quoteFormat: string;
  priceFormat: string;
  clearanceDate: Date;
  vsr: number;
  rpve: number;
  minReportOnlyVol: number;
  priceInterval: number;
  allOrNothingAllowed: boolean;
  atBestOrdersAllowed: boolean;
  stopOrdersAllowed: boolean;
  iceBergOrdersAllowed: boolean;
  holdOverOrdersAllowed: boolean;
  atCloseOrdersAllowed: boolean;
  futuresAnon: boolean;
  optionsAnon: boolean;
  siloCertAuctAnon: boolean;
  siloCertAuctBidInterval: number;
  optionExpiry: Date;
}

export interface MarketDisplayItemActiveOrder {
  activeOrderSeq: number;
  enterTime: number;
  userCode: string;
  userDealer: string;
  clearingMember: string;
  member: string;
  dealer: string;
  principal: string;
  buySell: string;
  orderState: string;
  quantity: number;
  contract: string;
  rate: number;
  referenceCode: string;
  suffixCode: number;
  profitCentre: string;
  subAccount: string;
  originalQuantity: number;
  principleAgency: string;
  gash: string;
}

export interface MarketDisplayItemHoliday {
  holidaySeq: string;
  centreCode: string;
  date: Date;
}
// export type FilterCondition = {
//   field: string;
//   value: any;
//   nested?: boolean;
// };

interface OrderBase {
  userDealer: string;
  clearingMember: string;
  member: string;
  dealer: string;
  principal: string;
  buySell: string;
  contract: string;
  contractDisplay: ContractDisplay;
  rate: number;
  suffixCode: number;
  profitCentre: string;
  subAccount: string;
  principleAgency: string;
}

interface ActiveOrderBase {
  activeOrderSeq: number;
  enterTime: string;
  userCode: string;
  orderState: string;
  quantity: number;
  referenceCode: string;
  xtraUserRef: string | null;
  originalQuantity: number;
  gash: string;
  isPreMarketOrder: boolean;
}

interface CompletedOrderBase {
  completedOrderSeq: number;
  enterTime: string;
  userMember: string;
  state: string;
  qty: number;
  xtraUserRef: string;
  userRef: string;
  origQty: number;
  dealtRate: number;
  dealtPrice: number;
  exchangeRef: string;
  tradeDate: Date;
  tradeTime: string;
  matchDate: Date;
  matchTime: string;
  counterParty: string;
  price: number;
  origin: string;
  reason: string;
  spotPrice: number;
  hitter: boolean;
}

export type ActiveOrder = OrderBase & ActiveOrderBase;

export interface CompletedOrder extends OrderBase, CompletedOrderBase {
  [key: string]: string | number | ContractDisplay | Date | boolean;
}
interface TimeSpan {
  time: string;
  // days: number;
  // hours: number;
  // milliseconds: number;
  // minutes: number;
  // seconds: number;
  // ticks: number;
  // totalDays: number;
  // totalHours: number;
  // totalMilliseconds: number;
  // totalMinutes: number;
  // totalSeconds: number;
}
export interface MtmViewModel {
  [key: string]: string | number | ContractDisplay | Date;
  contractSeq: number;
  dateSeq: number;
  strikeSeq: number;
  daysClose: number;
  openInterest: number;
  contract: string;
  change: number;
  contractDisplay: ContractDisplay;
  date: Date;
  spotPrice: number;
  volatility: number;
  hi: number;
  last: number;
  lo: number;
  volume: number;
  openPrice: number;
}
export interface MtmPriceViewModel {
  [key: string]: string | number;
  date: string;
  instrument: string;
  contractDate: string;
  flag: string;
  strike: number;
  last: number;
  vol: number;
  open: number;
  hi: number;
  lo: number;
  change: number;
  volume: number;
  lastUpdate: string;
  oi: number;
}

export interface IContractDate {
  contract: undefined;
  instrumentSeq: number;
  contractDateSeq: number;
  expiryDate: Date;
  expiryMonths: number;
  valuationDate: string;
  nominal: number;
  strikeInterval: number;
  strikeIntervalOffScreen: number;
  spreadMargin: number;
  lotSize: number;
  optionLotSize: number;
  bigDepth: number;
  priceRate: string;
  maxChange: number;
  maxDaysMove: number;
  maxGap: string;
  optionsAllowed: boolean;
  deltasAllowed: boolean;
  spreadsAllowed: boolean;
  initialMargin: number;
  quoteFormat: string;
  priceFormat: string;
  clearanceDate: string;
  vsr: number;
  rpve: number;
  minReportOnlyVol: number;
  priceInterval: number;
  allOrNothingAllowed: boolean;
  atBestOrdersAllowed: boolean;
  stopOrdersAllowed: boolean;
  iceBergOrdersAllowed: boolean;
  holdOverOrdersAllowed: boolean;
  atCloseOrdersAllowed: boolean;
  futuresAnon: boolean;
  optionsAnon: boolean;
  siloCertAuctAnon: boolean;
  siloCertAuctBidInterval: number;
  optionExpiry: string;
}

export interface DepthContractOverview {
  clearanceDate: string;
  deliveryFee: number;
  description: string;
  futureFee: number;
  groupMargin: number;
  initialMargin: number;
  instrumentName: string;
  maxChange: number;
  maxDaysMove: number;
  nominal: number;
  optionExpiry: string;
  optionFee: number;
  optionLotSize: string;
  physicallSettlement: string; // Note: Possible typo in "physicallSettlement" - should it be "physicalSettlement"?
  priceInterval: number;
  settlementMargin: number;
  spreadMargin: number;
  strikeInterval: number;
  valuationDate: string;
}

export interface DailyTrend {
  contract: string;
  contractDisplay: ContractDisplay;
  date: string;
  price: number;
  volume: number;
  time: string;
}
