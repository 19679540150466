// import { createBaseStore } from "@/store/baseStore";
import { MtmViewModel as MainModel } from "@/models/marketData";
import { MyStoreActions, createBaseStore } from "./baseStore";
import { ComputedRef, Ref, computed, ref, watchEffect } from "vue";
import { GroupNode, } from "@/utils/useWebsocket";
import { SortTableKey, useGroupingHook } from "@/utils/useGrouping";

export interface CustomMTMActions extends MyStoreActions<MainModel> {
  groupedData: ComputedRef<MainModel[] | GroupNode<MainModel>[]>;
}
export function useMTMStore(
  location: string | "default",
  groupBy: (keyof MainModel)[],
  sortBy?: Ref<SortTableKey<MainModel>[]>
) {
  const { group } = useGroupingHook<MainModel>();
  const groupedData = computed(() => {
    const currentIsOpenStates: Record<string, any> = {};

    if (
      groupedData.value &&
      Array.isArray(groupedData.value)
    ) {
      groupedData.value.forEach((group) => {
        if ("key" in group) {
          currentIsOpenStates[String(group.key)] = group.isOpen;
        }
      });
    }

    return group(
      store().getData.value,
      groupBy,
      sortBy?.value || [],
      currentIsOpenStates
    );
  });

  const store = createBaseStore<MainModel, {}>(
    "mtm",
    "dateSeq",
    undefined,
  );
  // return store;

  return {
    store: store(),
    groupedData: groupedData,
    sortBy,
    groupBy
  };
};
