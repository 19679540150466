import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { defineStore } from "pinia";
import { useAuthStore } from "./authStore";
import { ComputedRef, Ref, computed, ref } from "vue";
import { useToastStore } from "./toastStore";
import router from "@/router";
import { HttpTransportType } from "@microsoft/signalr";
import useConsoleLogger from "@/utils/useConsoleLogger";
import { DepthStoreType, useDepthConversions } from "@/utils/useDepthSocket";
import { DepthSnapshot, DepthUpdate } from "@/models/depth";
import { getActivePinia } from "pinia";
import { useDepthRegistry } from "./depthRegistry";
import { useMarketAnnouncementStore } from "./marketAnnouncements";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { useMarketDisplayStore } from "./marketDisplay";
import { createTypedArray } from "@/utils/useWebsocket";
import { MarketDisplayItemContract } from "@/models/marketData";

interface State {
  socket: HubConnection | null;
}
type SYSTEMSOCKETS = "standard" | "depth";
const pinia = getActivePinia();
export const useSystemSocketStore = defineStore("systemSocket", () => {
  const authStore = useAuthStore();
  const marketStore = useMarketDisplayStore();
  const { pascalToCamel, createTypedDepthRowUpdate, createTypedDepthUpdate } =
    useDepthConversions();
  const depthRegistry = useDepthRegistry();

  const endpoint = "/market";
  const socket: Ref<HubConnection | null> = ref(null);
  const depthSocket: Ref<HubConnection | null> = ref(null);
  const depthPromise: Ref<Promise<void> | null> = ref(null);
  let isHotReload = false;
  const isBackendReadyForTrading = ref(false);

  function $reset() {
    depthSocket.value?.stop();
    socket.value?.stop();
    depthPromise.value = null;
    depthSocket.value = null;
    socket.value = null;
  }
  const socketConnected = computed(() => {
    return socket.value?.state == "Connected" || false
  })

  // initialization
  const initializeSocket = async (
    onConnect: () => Promise<void>,
    funcs: {
      name: string;
      func: (message: string) => void;
    }[],
    selector?: SYSTEMSOCKETS
  ) => {
    const currentSocket =
      selector && selector == "depth" ? depthSocket : socket;
    if (isHotReload || !currentSocket.value) {
      currentSocket.value = new HubConnectionBuilder()
        .withUrl(
          `${
            import.meta.env.VITE_APP_API_URL
          }${endpoint}?access_token=${encodeURIComponent(authStore.token!)}`,
          {
            transport: HttpTransportType.WebSockets,
          }
        )
        .withHubProtocol(new MessagePackHubProtocol())
        .configureLogging(
          import.meta.env.MODE == "dev" ? LogLevel.Information : LogLevel.Error
        )
        .withAutomaticReconnect()
        .build();
      funcs.forEach((e) => {
        currentSocket.value?.on(e.name, e.func);
      });
      currentSocket.value.on("onConnected", async (message: string) => {
        useConsoleLogger.log(
          "Socket connected ",
          message,
          currentSocket.value?.connectionId
        );
      });

      currentSocket.value.on("InfoErrorMessage", async (message: string) => {
        useConsoleLogger.log(
          "InfoErrorMessage",
          message,
          currentSocket.value?.connectionId
        );

        useToastStore().addToast("info", message);
      });

      currentSocket.value.on("MarketAnnouncement", async (message: string) => {
        useConsoleLogger.log(
          "MarketAnnouncement Received",
          message,
          currentSocket.value?.connectionId
        );

        useToastStore().addToast("info", message);
        // useMarketAnnouncementStore().addAnnoucement(message);

        if (
          message.includes(
            "End of day completed, daily account summary and published positions ready for download"
          )
        ) {
          await authStore.logout();

          await router.push({
            name: "Login",
          });
        }
      });
      if (selector == "depth") {
        currentSocket.value.on(
          "DepthSnapshotUpdate",
          async (message: string) => {
            useConsoleLogger.log(
              "DepthSnapshotUpdate ",
              message,
              socket.value?.connectionId
            );
            onUpdate(message);
          }
        );
        currentSocket.value.on("DepthSnapshot", async (message: string) => {
          useConsoleLogger.log(
            "DepthSnapshot ",
            message,
            socket.value?.connectionId
          );
          onSnapshot(message);
        });
      } else {
        currentSocket.value.on("NewStrikeAdded", async (message: string) => {
          processStrikeAdded(message);
        });
        // currentSocket.value.on("StrikeUpdate", async (message: string) => {
        //   useConsoleLogger.log(
        //     "old Strike Updated ",
        //     message,
        //     socket.value?.connectionId
        //   );
        //   processStrikeAdded(message);
        // });
      }

      currentSocket.value.onclose = (event) => {
        console.log("Socket connection closed");
      };

      if (selector == "depth") {
        await connectDepthSocket();
        await onConnect();
      } else {
        await currentSocket.value
          .start()
          .then(async () => {
            useConsoleLogger.log(
              "Should be connected ",
              currentSocket.value?.state
            );
            await currentSocket.value?.invoke("SubscribeToStrike");
            await onConnect();
          })
          .catch((err) => {
            useConsoleLogger.log(err);
            return Promise.reject(err);
          });
      }
    } else {
      if (selector == "depth") {
        await connectDepthSocket();
        await onConnect();
        
      } else {
        await onConnect();
      }
    }
  };
  if (import.meta.hot) {
    import.meta.hot.accept();
    import.meta.hot.dispose(() => {
      isHotReload = true;
      closeSocket();
    });
  }
  const connectDepthSocket = async () => {
    if (depthPromise.value == null) {
      depthPromise.value = new Promise(async (resolve, reject) => {
        if (depthSocket.value) {
          try {
            const e = await depthSocket.value.start();
            setTimeout(() => resolve(e), 1000); // Resolve after 100ms delay
          } catch (error) {
            reject(error);
          }
          // depthSocket.value
          //   .start()
          //   .then((e) => {
          //     resolve(e);
          //   })
          //   .catch(reject);
        }
      });
    }
    return depthPromise.value;
  };
  // closure
  const closeSocket = async (type?: SYSTEMSOCKETS) => {
    const currentSocket = type && type == "depth" ? depthSocket : socket;
    if (currentSocket.value) {
      await currentSocket.value.stop();
    }
  };

  function onUpdate(message: string) {
    const parsedUpdates: DepthUpdate = createTypedDepthUpdate(
      JSON.parse(message)
    );
    const store: DepthStoreType | undefined = pinia
      ? (pinia.state.value[
          `depth-${parsedUpdates.displaySeq}`
        ] as DepthStoreType)
      : undefined;
    useConsoleLogger.log("On update ", parsedUpdates);
    const foundContract = marketStore().getData.value.find((e) => {
      return e.displaySeq == parsedUpdates.displaySeq;
    });
    if (!foundContract) throw "No contract for depth update";
    // for (const update of parsedUpdates) {
    for (const rowUpdate of parsedUpdates.depthUpdateList) {
      const storeType: DepthStoreType | undefined = depthRegistry.getStore(
        foundContract.contract
      );
      if (storeType) {
        storeType().updateRow(rowUpdate);
      }
    }
  }
  function onSnapshot(message: string) {
    const rawData = JSON.parse(message);
    useConsoleLogger.log("onSnapshot ", rawData);
    const data: DepthSnapshot = {
      displaySeq: rawData.DisplaySeq,
      depthRows: (rawData.DepthRows as any[]).map((row) => ({
        buyer: row.Buyer,
        bidQty: row.BidQty,
        bidPrice: row.BidPrice,
        sellPrice: row.SellPrice,
        offerQty: row.OfferQty,
        seller: row.Seller,
      })),
    };
    const foundContract = marketStore().getData.value.find((e) => {
      return e.displaySeq == data.displaySeq;
    });
    if (!foundContract) throw "No contract found for snapshot";
    const storeType: DepthStoreType | undefined = depthRegistry.getStore(
      foundContract.contract
    );
    if (storeType) {
      if (foundContract?.contract) {
        storeType().setID(foundContract.contract);
        storeType().setRows(data.depthRows);
      } else throw "No contract for dispplay seq";
    } else {
      console.warn(
        "No store found for snapshot",
        data.displaySeq,
        depthRegistry.dynamicStores
      );
    }
  }

  return {
    // expose state and methods
    socket,
    depthSocket,
    initializeSocket,
    closeSocket,
    isHotReload,
    $reset,
    isBackendReadyForTrading,
    socketConnected
  };
});

function processStrikeAdded(message: string) {
  try {
    const marketStore = useMarketDisplayStore();
    const temp = createTypedArray<MarketDisplayItemContract>(message);
    if (Number(temp[0].contractDisplay.CONTRACT_TYPE) === 2 || Number(temp[0].contractDisplay.CONTRACT_TYPE) === 5) {
      temp.forEach((e) => {
        marketStore().updateItem(e);
      });
    }
  } catch (err) {
    useConsoleLogger.error(
      "error parsing OPTION MARKET Strike UPDATE for ",
      message,
      err
    );
  }
}
