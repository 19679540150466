import { ComputedRef, Ref } from "vue";

export interface DepthRow {
  buyer: string | null;
  bidQty: number;
  bidPrice: number;
  sellPrice: number;
  offerQty: number;
  seller: string | null;
}

export interface DepthSnapshot {
  displaySeq: number;
  depthRows: DepthRow[];
}
export interface DepthStore {
  depths: Ref<DepthRow[]>;
  seq: Ref<string | null>;
  getID: ComputedRef<Ref<string | null>>;
  getRows: ComputedRef<Ref<DepthRow[]>>;
  setRows: (arr: DepthRow[]) => void;
  updateRow: (item: DepthRowUpdate) => void;
  setID: (id: string | null) => void;
}

export interface DepthRowUpdate {
  updateType: DepthRowUpdateInstruction;
  updateValue: string | number;
  rowNumber: number;
}
export enum DepthRowUpdateInstruction {
  CLEAR = 0,
  BUYER = 1,
  BID = 2,
  BID_QTY = 3,
  OFFER_QTY = 4,
  OFFER = 5,
  SELLER = 6,
}
export interface DepthUpdate {
  displaySeq: number;
  depthUpdateList: DepthRowUpdate[];
}

export type DepthUpdates = DepthUpdate[];

export const DepthRowUpdateMapping: Record<
  DepthRowUpdateInstruction,
  keyof DepthRow | null
> = {
  [DepthRowUpdateInstruction.CLEAR]: null, // Not needed as "CLEAR" is handled separately
  [DepthRowUpdateInstruction.BUYER]: "buyer",
  [DepthRowUpdateInstruction.BID]: "bidPrice",
  [DepthRowUpdateInstruction.BID_QTY]: "bidQty",
  [DepthRowUpdateInstruction.OFFER_QTY]: "offerQty",
  [DepthRowUpdateInstruction.OFFER]: "sellPrice",
  [DepthRowUpdateInstruction.SELLER]: "seller",
};
