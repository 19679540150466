import { IUserPref, UserLayout, UserPreferences } from "@/models/UI";
import { LoginResponse, Member } from "@/models/auth";
import { LAYOUT } from "@/models/layout";
import { Capacity, IUser } from "@/models/trading";
import {
  getHQAccessProfile,
  createUserPref,
  updateUserPref,
  loadUserPref,
  getMembers,
  submitLogin,
  deletePreferences as deletePref,
} from "@/utils/api";
import useConsoleLogger from "@/utils/useConsoleLogger";
import { Base64 } from "js-base64";
import { defineStore } from "pinia";
import { Ref, computed, ref } from "vue";
import { useLayoutStore } from "./layout";
import { useMTMStore } from "./mtm";
import { useMarketDisplayStore } from "./marketDisplay";
import { useToastStore } from "./toastStore";
import { useContractDateStore } from "./contractDate";
import { useMarketAnnouncementStore } from "./marketAnnouncements";
import { usePositionsStore } from "./positions";
import { useConsPositionsStore } from "./consPositions";
import { useDealsStore } from "./deals";
import { useBreakdownStore } from "./breakdown";
import { useSystemSocketStore } from "./systemSocket";
import { useDefaultTrader } from "@/utils/useDefaultTrader";
import { AxiosError } from "axios";
import { useUserPreferences } from "@/utils/useUserPreferences";

// Function to decode a JWT
function decodeJwt(token: string) {
  if (!token) {
    return null;
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/") + "==";
  const jsonPayload = decodeURIComponent(
    Base64.atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
}
export const useAuthStore = defineStore("auth", () => {
  const charts = ref(false);
  // define the state using the State interface
  const login: Ref<LoginResponse | null> = ref(null);
  const clearingLogin: Ref<LoginResponse | null> = ref(null);
  const hq: Ref<IUser | null> = ref(null);
  const userPreference: Ref<UserPreferences | null> = ref(null);
  const members: Ref<Member[]> = ref([]);
  const loaders = ref({
    hq: false,
    login: false,
    members: false,
    updateLayout: false,
    saveLayout: false,
    updateUserPref: false,
    createUserPref: false,
  });
  const getChart = computed(() => {
    return charts.value;
  });

  const getClearingToken = computed(() => {
    return clearingLogin.value;
  });
  const isLoggedIn = computed(() => {
    return login.value !== null;
  });
  const token = computed(() => {
    return login.value?.token;
  });
  const getHQ = computed(() => {
    return hq.value;
  });
  const getUserPref = computed(() => {
    return userPreference.value;
  });
  const decodedToken = computed(() => {
    if (!login.value) return null;
    return decodeJwt(login.value.token);
  });
  const currentLoaders = computed(() => {
    return loaders.value;
  });
  const isTokenExpired = computed(() => {
    if (!login.value) return true;
    const decodedToken = decodeJwt(login.value.token);

    if (!decodedToken) return true;

    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  });
  const getUsername = computed(() => {
    if (!decodedToken.value) return;
    const temp = Object.keys(decodedToken.value).find((e) =>
      e.includes("name")
    );
    if (temp) return decodedToken.value[temp];

    // return decodedToken.value.name;
  });
  function toggleChart () {
    charts.value = !charts.value;
  }
  function setClearingLoginResponse(loginResponse: LoginResponse) {
    clearingLogin.value = loginResponse;
  }
  function setLoginResponse(loginResponse: LoginResponse) {
    login.value = loginResponse;
  }
  function $reset() {
    userPreference.value = null;
    hq.value = null;
    login.value = null;
    members.value.splice(0);
  }
  async function logout() {
    await clearLastLayoutToken();
    await clearToken();
    await clearLoginResponse();
    window.location.reload()
    // setTimeout(window.location.reload ,100)
    // window.location.reload();
  }
  function setHQ(newhq: IUser | null) {
    hq.value = newhq;
  }
  function setUserPref(item: UserPreferences) {
    userPreference.value = item;
    if (!item.defaults.tradeFormLocation) {
      userPreference.value.defaults.tradeFormLocation = "last" 
    }
  }
  function setMembers(items: Member[]) {
    members.value = items;
  }
  function clearLoginResponse() {
    login.value = null;
  }
  function setToken(token: string) {
    if (login.value) {
      login.value.token = token;
    } else {
      login.value = {
        token: token,
        expiration: "2222-05-05",
      };
    }
    localStorage.setItem("jwt", token);
  }
  function clearLastLayoutToken() {
    localStorage.removeItem("lastUsedLayout");
  }

  function clearToken() {
    if (login) {
      login.value = null;
      localStorage.removeItem("jwt");
    }
  }
  async function updateUserPreference(item: UserPreferences) {
    const res = await updateUserPref(item);
    setUserPref(res);
  }
  function isMissingPreferenceError(error: any): boolean {
    return (
      (error instanceof AxiosError &&
        error.response &&
        error.response.status == 404) ||
      false
    );
  }
  async function loadUserPreference() {
    try {
      const userPref = await loadUserPref();
      setUserPref(userPref);
    } catch (error) {
      if (isMissingPreferenceError(error)) {
        const defaultPref = await createAndSaveDefaultPreference();
        setUserPref(defaultPref);
      } else {
        useToastStore().addToast("error", JSON.stringify(error))
        useConsoleLogger.error(error);
      }
    }
  }
  async function createAndSaveDefaultPreference(): Promise<UserPreferences> {
    const { createDefaultPreference } = useUserPreferences();
    const defaultPref = createDefaultPreference();
    return await createUserPref(defaultPref);
  }
  async function loadHQAccess(): Promise<boolean> {
    if (token.value == null) return false;
    const res = await getHQAccessProfile();
    setHQ(res);
    return true;
  }
  async function loadMembers(): Promise<boolean> {
    if (token.value == null) return false;
    const members = await getMembers();
    setMembers(members);
    return true;
  }
  async function deletePreferences() {
    const res = await deletePref();
  }
  async function loginUser(username: string, password: string) {
    const login = await submitLogin(username, password);

    if (login) {
      setLoginResponse({ userName: username, ...login });
      if (login.token) {
        setToken(login.token);
      }
      return true;
    } else {
      clearLoginResponse();
      return false;
    }
  }
  return {
    getHQ,
    userPreference,
    token,
    hq,
    login,
    getMembers,
    logout,
    loginUser,
    deletePreferences,
    loadMembers,
    loadHQAccess,
    loadUserPreference,
    updateUserPreference,
    setClearingLoginResponse,
    setToken,
    clearToken,
    decodedToken,
    isTokenExpired,
    getUserPref,
    members,
    getUsername,
    $reset,
    toggleChart,
    getChart
  };
});
