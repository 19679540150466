import { UserLayout } from "@/models/UI";

export const MOBILE_LAYOUTS: UserLayout[] = [
  {
    userLayoutJson: {
      name: "Futures Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Futures",
          id: "futures-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Options Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Options",
          id: "options-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Deltas Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Deltas",
          id: "deltas-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Splits Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Splits",
          id: "splits-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Spreads Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Spreads",
          id: "spreads-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Active Orders Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "ActiveOrders",
          id: "active-orders-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Completed Orders Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "CompletedOrders",
          id: "completed-orders-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Positions Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Positions",
          id: "positions-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Consolidated Positions Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "ConsolidatedPositions",
          id: "cons-positions-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Deals Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Deals",
          id: "deals-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Unmatched Deals Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "unMatchedDeals",
          id: "unmatched-deals-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "MTM Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "MTM",
          id: "mtm-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Option Calculator Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "OptionCalculator",
          id: "options-calc-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Clearing Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Clearing",
          id: "clearing-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Breakdown Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "Breakdown",
          id: "breakdown-mobile",
        },
      ],
    },
  },
  {
    userLayoutJson: {
      name: "Chart Mobile",
      isMobile: true,
      columns: [
        {
          grid: {
            "grid-column": "1 / span 12",
            "grid-row": "1 / span 6",
          },
          coordinates: {
            x: 0,
            y: 0,
            w: 100,
            z: 100,
            h: 100,
          },
          color: "grey",
          content: "Column 1",
          component: "TVChartContainer",
          id: "default-chart-mobile",
        },
      ],
    },
  },
];
