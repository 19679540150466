import {
  DepthRow,
  DepthRowUpdate,
  DepthStore,
  DepthRowUpdateInstruction,
  DepthRowUpdateMapping,
} from "@/models/depth";
import { defineStore } from "pinia";
import { Ref, computed, ref } from "vue";
import { useToastStore } from "./toastStore";
import useConsoleLogger from "@/utils/useConsoleLogger";
import { useDepthRegistry } from "./depthRegistry";

interface State {
  depths: DepthRow[];
  id: number | null; // id of sequence started on
}

export const useDepthStore = (depthContractName: string | undefined) => {
  const depthRegistry = useDepthRegistry();
  const store = defineStore(`depth-${depthContractName}`, (): DepthStore => {
    // define the state using the State interface
    const depths: Ref<DepthRow[]> = ref([]);
    const seq: Ref<string | null> = ref(depthContractName || null);

    const getRows = computed(() => {
      return depths;
    });
    const getID = computed(() => {
      return seq;
    });
    function setRows(arr: DepthRow[]) {
      depths.value = arr;
    }
    function updateRow(update: DepthRowUpdate) {
      const fieldToUpdate = DepthRowUpdateMapping[update.updateType];
      if (update.updateType === DepthRowUpdateInstruction.CLEAR) {
        depths.value.splice(update.rowNumber, 1);
      } else if (fieldToUpdate) {
        // useConsoleLogger.log("Update field ", fieldToUpdate, update);
        // check if the row exists, if not create an empty object for that row
        if (depths.value[update.rowNumber] === undefined) {
          depths.value[update.rowNumber] = {
            buyer: null,
            bidPrice: 0,
            bidQty: 0,
            seller: null,
            sellPrice: 0,
            offerQty: 0,
          };
          //toastStore.addToast("warning", "Adding Row for depth update")
        }
        if (fieldToUpdate === "buyer" || fieldToUpdate === "seller") {
          depths.value[update.rowNumber][fieldToUpdate] =
            update.updateValue as string;
        } else {
          depths.value[update.rowNumber][fieldToUpdate] =
            update.updateValue as number;
        }
      }
    }
    function setID(newId: string | null) {
      seq.value = newId;
    }

    return {
      depths,
      seq,
      getRows,
      getID,
      setRows,
      updateRow,
      setID,
    };
  });
  if (depthContractName) {
    depthRegistry.register(depthContractName, store);
  }

  return store;
};
