<template>
  <v-dialog :scrim="true" v-model="isOffline" persistent width="400">
    <v-card>
      <v-card-title class="bg-primary">Offline Mode Activated</v-card-title>
      <v-card-text>
        <v-alert type="warning" icon="mdi-wifi-off">
          Live trading and real-time data require an active internet connection.
        </v-alert>
        <div class="my-4">
          Please check your network settings, or try again later. We're here to
          support your trading journey and will be ready as soon as you're back
          online.
        </div>
      </v-card-text>
      <!-- <v-card-actions>
        <v-btn color="primary" text @click="closeOfflineDialog">Okay</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";

const isOffline = ref(!navigator.onLine);

// Watch for online/offline status changes
watch(
  () => navigator.onLine,
  (online) => {
    isOffline.value = !online;
  }
);

// Optional: handle the 'online' and 'offline' events to update the state
window.addEventListener("online", () => (isOffline.value = false));
window.addEventListener("offline", () => (isOffline.value = true));
</script>
