const env_mode = import.meta.env.MODE;


const consoleLogger = () => {
  console.log("Logger for env: ", env_mode);
  const log = (message: any, ...optionalParams: any[]) => {
    if (env_mode == "dev" || env_mode == "production") {
      if (optionalParams.length > 0) {
        console.log(message, optionalParams);
      } else {
        console.log(message);
      }
    }
  };

  const warn = (message: any, ...optionalParams: any[]) => {
    // if (env_mode == "dev") {
      if (optionalParams.length > 0) {
        console.warn(message, optionalParams);
      } else {
        console.warn(message);
      }
    // }
  };

  const error = (message: any, ...optionalParams: any[]) => {
    // if (env_mode == "dev") {
      if (optionalParams.length > 0) {
        console.error(message, optionalParams);
      } else {
        console.error(message);
      }
    // }
  };

  return { log, warn, error };
};

const useConsoleLogger = consoleLogger();
export default useConsoleLogger;
