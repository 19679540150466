import axios from "axios";
import useConsoleLogger from "./useConsoleLogger";

interface ServerErrorData {
  status: string;
  message: string;
}

const generateMessageFromError = (error: unknown): string => {
  let message = "";
  if (axios.isAxiosError(error)) {
    if (error.response) {
      if (error.response.data && typeof error.response.data == "object") {
        // for our BE
        if (
          (error.response.data as ServerErrorData).status &&
          (error.response.data as ServerErrorData).message
        ) {
          message = (error.response.data as ServerErrorData).status + ": ";
          message += (error.response.data as ServerErrorData).message;
        } else if (
          (error.response.data as any).status &&
          (error.response.data as any).title
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).title + `<br />`;
          const errs = (error.response.data as any).errors;
          if (errs) {
            Object.entries(errs).forEach((e) => {
              message += e[1] + `<br />`;
            });
          }
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.data) {
        const msg = (error.response.status +
          ": " +
          error.response.data) as string;
        message = msg.substring(0, 300) + (msg.length > 300 ? "..." : "");
      } else if (error.response.status && error.response.statusText) {
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else if (error.message) {
        message = error.message;
      } else {
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    if (error instanceof Error) {
      if (error.message) {
        message = error.message;
      } else {
        useConsoleLogger.error("error non message", error);
      }
    } else if (typeof error === "object") {
      const temp = JSON.stringify(error);
      if (temp) {
        message = temp;
      } else {
        useConsoleLogger.error("Couldnt stringify error", error);
      }
    } else {
      message = "Unidentified error: " + error;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};

export { generateMessageFromError };
