import { Store, StoreActions, defineStore } from "pinia";
import { Ref, ref, computed, ComputedRef } from "vue";
import { MarketDisplayItemContract as MainModel } from "@/models/marketData";
import { CustomActions, GroupNode } from "@/utils/useWebsocket";
import { MarketDisplayStoreActions } from "./marketDisplay";
import { CustomActiveOrderActions } from "./customActiveOrders";
import useConsoleLogger from "@/utils/useConsoleLogger";

export interface BasicStore<T> {
  setData: (items: T[]) => void;
  getData: ComputedRef<Ref<T[]>>;
  updateItem: (updatedItem: T) => void;
}
export type MyStoreActions<T> = {
  [key: string]:
    | ((...args: any[]) => Promise<any> | any)
    | ComputedRef<T[] | GroupNode<T>[]>;
  // [K in keyof T]: () => T[K];
};
export type CustomAction = MarketDisplayStoreActions | CustomActiveOrderActions;

export type MyStoreGetters = {
  [key: string]: ComputedRef<any>;
};
export function createBaseStore<T, CustomAction>(
  name: string,
  identifier: keyof T,
  baseFunc?: {
    updateEvent: ((updatedItem: T) => void) | undefined;
  },
  customActions?: CustomAction
) {
  const { updateEvent } = baseFunc || {};
  const modelKeyIdentifier = identifier;

  const store = defineStore(name, () => {
    const data = ref([]) as Ref<T[]>;
    const getData = computed(() => data);

    function setData(items: T[]) {
      data.value = items;
    }
    function $reset() {
      data.value.splice(0);
    }
    // function updateObjectFields<T>(target: T, source: T): T {
    //   for (const key in source) {
    //     if (
    //       Object.prototype.hasOwnProperty.call(source, key) &&
    //       target[key] !== source[key as keyof T]
    //     ) {
    //       target[key as keyof T] = source[key as keyof T];
    //     }
    //   }
    //   return target;
    // }
    function deepMerge<T>(target: T, source: T, called = "not set"): T {
      for (const key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          const keyString = key as keyof T;
          if (isObject(source[keyString])) {
            if (target[keyString] === undefined) {
              target[keyString] = source[keyString];
            } else {
              deepMerge(
                target[keyString],
                source[keyString],
                called + "-innerdeep"
              );
            }
          } else {
            if (keyString != "openInterest") {
              target[keyString] = source[keyString];
            } else {
              if (source[keyString] == 0) {
                // ignore
              } else {
                target[keyString] = source[keyString];
              }
            }
          }
        }
      }
      return target;
    }
    function removeItem(id: number) {
      const temp = data.value.findIndex((e) => {
        useConsoleLogger.log(
          "modelKeyIdentifier (base store)",
          modelKeyIdentifier
        );
        useConsoleLogger.log(
          "e[modelKeyIdentifier] (base store)",
          e[modelKeyIdentifier]
        );
        return e[modelKeyIdentifier] === id;
      });

      useConsoleLogger.log("temp remove item (base store 1)", temp);
      if (temp != -1) {
        useConsoleLogger.log("temp remove item (base store 2)", temp);
        data.value.splice(temp, 1);
      }
    }

    function isObject(item: any): boolean {
      return item && typeof item === "object" && !Array.isArray(item);
    }
    function updateItem(updatedItem: T, calledFrom = "not set") {
      if (updateEvent) {
        updateEvent(updatedItem);
        return;
      }
      const temp = data.value.findIndex(
        (e) => e[modelKeyIdentifier] === updatedItem[modelKeyIdentifier]
      );
       //console.log("Update item from ", calledFrom);
      // useConsoleLogger.log("temp", temp)
      if (temp == -1) {
        data.value.push(updatedItem);
      } else {
        deepMerge(data.value[temp], updatedItem, "base store");
      }
    }
    if (typeof customActions == "object" && customActions != null) {
      return {
        data,
        getData,
        setData,
        removeItem,
        deepMerge,
        updateItem,
        $reset,
        customActions: {
          customActions,
        },
      };
    } else {
      return {
        data,
        getData,
        setData,
        removeItem,
        deepMerge,
        updateItem,
        $reset,
        customActions: {},
      };
    }
  });
  return store;
}
