<template>
  <v-snackbar
    timeout="-1"
    width="400"
    :z-index="100000"
    location="bottom right"
    :model-value="
      canInstall &&
      onTrading &&
      appStore.appInitState.initializing == false &&
      shouldShowPrompt
    "
    color="white"
    content-class="pwa-refresh-toast"
    vertical
  >
    <div class="text-subtitle-1 pb-2 font-weight-medium">
      <span>Enhance your trading experience! </span>
    </div>
    <div>
      Install our app for easy and fast access to the markets anytime, anywhere.
    </div>

    <!-- <template v-slot:actions> -->
    <div style="width: 100%" class="mt-4">
      <div class="mb-2 d-flex justify-space-between">
        <v-btn
          @click="silencePromptForever"
          color="primary"
          size="small"
          variant="outlined"
          class="mr-2"
        >
          Don't Remind Me Again
        </v-btn>
        <v-btn
          @click="declinePrompt"
          color="primary"
          size="small"
          variant="outlined"
        >
          Remind me later
        </v-btn>
      </div>
      <v-btn
        v-if="canInstall"
        @click="promptInstall"
        color="primary"
        variant="flat"
        size="small"
        block
      >
        Install
      </v-btn>
    </div>
    <!-- </template> -->
  </v-snackbar>
</template>

<script setup lang="ts">
import { useAppStore } from "@/store/app";
import { computed } from "vue";
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

const installPromptEvent = ref<Event | null>(null);
const canInstall = ref(false);
const appStore = useAppStore();
const onTrading = computed(() => {
  console.log("Route name");
  if (route.name == "Trading") {
    return true;
  }
  console.log("Not trading");
  return false;
});
const STORAGE_KEY_SILENCE_FOREVER = "pwaInstallPromptSilencedForever";
const STORAGE_KEY = "pwaInstallPromptDeclined";
const COOLDOWN_PERIOD = 24 * 60 * 60 * 1000; // 1 day
// const COOLDOWN_PERIOD = 1 * 60 * 1000; // 1min
function declinePrompt(): void {
  const data = {
    declined: true,
    timestamp: Date.now(),
  };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  canInstall.value = false;
}
const lastCheck = ref(Date.now());
onMounted(() => {
  console.log("Before isntall prompt ");
  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    installPromptEvent.value = e;
    canInstall.value = true;
  });
});
const route = useRoute();

setInterval(() => {
  lastCheck.value = Date.now();
}, 60000);

const shouldShowPrompt = computed(() => {
  const silencedForever = localStorage.getItem(STORAGE_KEY_SILENCE_FOREVER);
  if (silencedForever === "true") {
    return false;
  }
  const declinedData = localStorage.getItem(STORAGE_KEY);
  if (!declinedData) {
    return true;
  }

  const { declined, timestamp } = JSON.parse(declinedData);
  const timeElapsed = lastCheck.value - timestamp;

  return !declined || timeElapsed > COOLDOWN_PERIOD;
});
function promptInstall() {
  if (installPromptEvent.value) {
    const promptEvent = installPromptEvent.value as any;
    promptEvent.prompt();
    // Optionally, handle the user's response to the prompt
    promptEvent.userChoice.then((choiceResult: { outcome: string }) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      installPromptEvent.value = null;
      canInstall.value = false;
    });
  }
}
function silencePromptForever(): void {
  localStorage.setItem(STORAGE_KEY_SILENCE_FOREVER, "true");
  canInstall.value = false;
}
</script>

<style>
.pwa-refresh-toast {
  border: 2px solid rgb(var(--v-theme-primary));
}
</style>
