// Utilities
import { ComponentRegistry } from "@/models/componentRegistry";
import { COLUMN, LAYOUT } from "@/models/layout";
import { defineStore } from "pinia";
import { useToastStore } from "./toastStore";
import { PossiblePropTypes, UserLayout } from "@/models/UI";
import useConsoleLogger from "@/utils/useConsoleLogger";
import { loadUserLayoutAPI, saveLayout, updateLayout } from "@/utils/api";
import { useAuthStore } from "./authStore";
import { MarketDisplayItemContract } from "@/models/marketData";
import { SortTableKey } from "@/utils/useGrouping";
import { DisplayInstance, useDisplay } from "vuetify";
import vuetify from "@/plugins/vuetify";
import { MOBILE_LAYOUTS } from "@/setup/mobileLayouts";
import { Coordinate } from "@/models/layout";

import { v4 as uuidv4 } from "uuid";

interface State {
  layout: UserLayout | null;
  layoutOptions: UserLayout[];
  savedLayouts: UserLayout[];
  userLayoutJson: UserLayout[];
  authStore: any;
  toastStore: any;
  vuetify: DisplayInstance;
  mobileLayoutOptions: UserLayout[];
  navDrawer: boolean;
  logoMenu: boolean;
  spawnPoint: { x: undefined | number; y: undefined | number };
  spawnPointConfirmations: { x: undefined | number; y: undefined | number };
  lastTradeForm: {
    id: string | undefined;
    x: undefined | number;
    y: undefined | number;
  };
  locked: boolean;
  resizable: boolean;
  moveSpawn: boolean;
  collapseToolbar: boolean;
}

// const authStore = useAuthStore();
// const toastStore = useToastStore();

const getMobileHeight = () => {
  const screen = window.innerHeight;
  const toSubtract = Math.floor((screen - 75) * 0.3);

  return Math.floor((screen - toSubtract) / 50);
};
export const useLayoutStore = defineStore("layout", {
  state: (): State => ({
    logoMenu: false,
    userLayoutJson: [],
    savedLayouts: [],
    layout: startingLayoutFull,
    layoutOptions: layoutOptions.map((e) => {
      const userLayout: UserLayout = {
        userLayoutJson: e,
      };
      return userLayout;
    }),
    authStore: useAuthStore(),
    toastStore: useToastStore(),
    vuetify: useDisplay(),
    navDrawer: vuetify.display.smAndUp.value || false,
    mobileLayoutOptions: MOBILE_LAYOUTS,
    locked: false,
    resizable: true,
    moveSpawn: false,
    spawnPoint: {
      x: undefined,
      y: undefined,
    },
    spawnPointConfirmations: {
      x: 50,
      y: 50,
    },
    lastTradeForm: {
      id: "tradeFormSpawn",
      x: undefined,
      y: undefined,
    },
    collapseToolbar:
      vuetify.display.xs.value == true ||
      vuetify.display.platform.value.android == true ||
      vuetify.display.platform.value.ios == true
        ? false
        : true,
  }),
  actions: {
    updateColumnCoordinates(id: string, newCoordinates: Coordinate) {
      if (!this.layout) return;
      const column = this.layout.userLayoutJson.columns.find(
        (c) => c.id === id
      );
      if (column) {
        column.coordinates = newCoordinates;
      } else console.log("Not found for update", id);
    },
    $reset() {
      this.savedLayouts = [];
      this.layout = null;
      // this.navDrawer = false;
    },
    toggleDrawer() {
      this.navDrawer = !this.navDrawer;
    },
    toggleLogoMenu() {
      this.logoMenu = !this.logoMenu;
    },

    addComponent(
      compName: keyof ComponentRegistry,
      props: PossiblePropTypes,
      boundingClientRect?: DOMRect,
      colId?: string | number
    ) {
      if (!this.layout) throw "No Layout to add";

      const componentWidthPercentage = 40;
      const componentHeightPercentage = 40;

      const defaultDimensions: {
        [key: string]: {
          w: number;
          h: number;
        };
      } = {
        Depth: { w: 20, h: 40 },
        TVChartContainer: { w: 40, h: 40 },
        OptionCalculator: { w: 30, h: 50 },
        default: { w: 48, h: 40 },
      };

      const dimensions =
        defaultDimensions[compName] || defaultDimensions.default;

      let spawnX = this.spawnPoint.x || 0;
      let spawnY = this.spawnPoint.y || 0;

      // Adjust spawnX and spawnY if boundingClientRect is provided
      if (boundingClientRect) {
        const containerWidth = boundingClientRect.width;
        const containerHeight = boundingClientRect.height;

        // Convert the spawn point percentages to pixels
        const spawnXpx = (spawnX / 100) * containerWidth;
        const spawnYpx = (spawnY / 100) * containerHeight;

        // Calculate the right and bottom edges of the new component
        // Calculate the right and bottom edges of the new component
        const componentRightEdge =
          spawnXpx + (dimensions.w / 100) * containerWidth;
        const componentBottomEdge =
          spawnYpx + (dimensions.h / 100) * containerHeight;

        // Adjust spawnX and spawnY to ensure the component stays within bounds
        if (componentRightEdge > containerWidth) {
          spawnX =
            ((containerWidth - (dimensions.w / 100) * containerWidth) * 100) /
            containerWidth;
        }
        if (componentBottomEdge > containerHeight) {
          spawnY =
            ((containerHeight - (dimensions.h / 100) * containerHeight) * 100) /
            containerHeight;
        }
      }

      const toasts = useToastStore();
      const avail = this.getNextAvailableSlot;

      this.layout.userLayoutJson.columns.push({
        grid: {
          "grid-column": "1 / span 6",
          "grid-row": "1 / span 6",
        },
        coordinates: {
          x: spawnX,
          y: spawnY,
          w: dimensions.w,
          h: dimensions.h,
          z: 150,
        },
        color: "grey",
        content: "Column 1",
        component: compName,
        id: props.id || `${compName}-${uuidv4()}`,
        props: props,
        style: `grid-item-${compName.toLowerCase()}`,
      });
    },
    removeComponent(colId: string) {
      const res = this.layout?.userLayoutJson.columns.findIndex(
        (col) => col.id == colId
      );
      if (res != undefined) {
        this.layout?.userLayoutJson.columns.splice(res, 1);
      }
    },
    setSorting<T extends Object>(colId: string, sortBy: SortTableKey<T>[]) {
      const res = this.layout?.userLayoutJson.columns.findIndex(
        (col) => col.id == colId
      );
      if (res != undefined) {
        this.layout!.userLayoutJson.columns[res].sortingOrder = sortBy;
      }
    },
    setGrouping(colId: string, groupBy: string[]) {
      const res = this.layout?.userLayoutJson.columns.findIndex(
        (col) => col.id == colId
      );
      if (res != undefined) {
        this.layout!.userLayoutJson.columns[res].groupBy = groupBy;
      }
    },
    setInstrumentSubscriptions(
      colId: string,
      subscriptions: MarketDisplayItemContract[]
    ) {
      const res = this.layout?.userLayoutJson.columns.findIndex(
        (col) => col.id == colId
      );
      if (res != undefined) {
        this.layout!.userLayoutJson.columns[res].instrumentSubscriptions =
          subscriptions;
      }
    },
    setTableHeaders(colId: string, subscriptions: any[]) {
      const res = this.layout?.userLayoutJson.columns.findIndex(
        (col) => col.id == colId
      );
      if (res != undefined) {
        this.layout!.userLayoutJson.columns[res].tableHeaders = subscriptions;
      }
    },
    setDialogPosition(position: { x: number; y: number }) {
      console.log("Dialog position ", position);
      this.spawnPointConfirmations = position;
    },
    setCurrentLayout(layout: UserLayout | null) {
      console.log("Set current layout ", layout);
      if (layout) {
        this.moveSpawn = layout.userLayoutJson.spawnPointMove || false;
        this.locked = layout.userLayoutJson.locked || false;
        this.spawnPoint = layout.userLayoutJson.spawnPoint || {
          x: 20,
          y: 20,
        };
        this.resizable = layout.userLayoutJson.resizeable || true;
        this.spawnPointConfirmations = layout.userLayoutJson
          .spawnPointConfirmations || {
          x: 50,
          y: 50,
        };
      }

      this.layout = layout;
      if (layout?._id) {
        localStorage.setItem("lastUsedLayout", layout._id.toString());
      } else {
        localStorage.removeItem("lastUsedLayout");
      }
    },
    setLayoutOptions(layouts: UserLayout[]) {
      this.layoutOptions = layouts;
    },
    changeColumnComponent(comp: keyof ComponentRegistry, colId: string) {
      // only works for selectedLayout else add layoutId: string
      const found = this.currentLayout?.userLayoutJson.columns.find(
        (e) => e.id == colId
      );
      if (found) {
        // found
        found.component = comp;
      }
    },
    setUserLayouts(items: UserLayout[]) {
      // this.userLayoutJson = items;
      this.savedLayouts = items;
    },
    async saveMobileUserLayout(item: UserLayout) {
      if (!this.authStore.getHQ) {
        this.toastStore.addToast("error", "No HQ for saving");
        return;
      }
      if (item._id) {
        const res = await updateLayout(item);
        this.toastStore.addToast("success", "Mobile Layout updated");
      } else {
        const res = await saveLayout(item, this.authStore.getHQ.userId);
        this.toastStore.addToast("success", "Mobile Layout saved");
      }
    },
    async saveUserLayout(item: UserLayout) {
      if (!this.authStore.getHQ) {
        this.toastStore.addToast("error", "No HQ for saving");
        return;
      }

      const res = await saveLayout(item, this.authStore.getHQ.userId);
      this.savedLayouts.push(res);
      this.setCurrentLayout(res);
      this.toastStore.addToast("success", "Layout saved");
    },
    async updateUserLayout(item: UserLayout) {
      const res = await updateLayout(item);
      this.setCurrentLayout(res);
      const found = this.savedLayouts.findIndex((e) => e._id == res._id);
      if (found != -1) {
        this.savedLayouts[found] = res;
        this.toastStore.addToast(
          "success",
          `Updated layout: ${item.userLayoutJson.name}`
        );
      } else {
        this.toastStore.addToast(
          "warning",
          `Not found to updated saved layout: ${JSON.stringify(res)}`
        );
      }
    },
    async loadUserLayoutAPI() {
      const res = await loadUserLayoutAPI();
      this.setUserLayouts(res);
    },
    async setDefaultLayout() {
      if (this.isMobile == true) {
        this.setCurrentLayout(this.mobileLayoutOptions[0]);
      } else {
        this.setCurrentLayout({
          userLayoutJson: startingLayout,
        });
      }
    },
  },

  getters: {
    currentLayout: (state): UserLayout | null => state.layout,
    isMobile: (state) => {
      return (
        state.vuetify.xs ||
        state.vuetify.platform.android ||
        state.vuetify.platform.ios
      );
    },
    currentColumns: (state) => state.layout?.userLayoutJson.columns,
    getSavedLayouts: (state): UserLayout[] => state.savedLayouts,
    getUserLayouts: (state) => state.userLayoutJson,
    getNextAvailableSlot: (state): COLUMN | null => {
      if (!state.layout) return null;
      if (state.layout.userLayoutJson.columns.length == 0) return null;
      const col: COLUMN | undefined = state.layout.userLayoutJson.columns.find(
        (e) => e.component == null
      );
      if (col) {
        return col;
      } else {
        return null;
      }
    },
    getHasBreakdown: (state): Boolean => {
      const columns = state.layout?.userLayoutJson.columns;
      if (!columns || (columns && columns.length <= 0)) return false;
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].component == "Breakdown") return true;
      }
      return false;
    },
    getHasClearing: (state): Boolean => {
      const columns = state.layout?.userLayoutJson.columns;
      if (!columns || (columns && columns.length <= 0)) return false;
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].component == "Clearing") return true;
      }
      return false;
    },
  },
});

const startingLayout: LAYOUT = {
  name: "Blank Layout",
  columns: [],
};
const startingLayoutFull: UserLayout = {
  userLayoutJson: startingLayout,
};

const layoutOptions: LAYOUT[] = [
  {
    name: "Blank Layout",
    columns: [],
  },
];
