import { DepthStoreType } from "@/utils/useDepthSocket";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useDepthRegistry = defineStore("storeRegistry", () => {
  const dynamicStores: {
    [key: string]: DepthStoreType;
  } = reactive({});

  function register(id: string, store: DepthStoreType) {
    dynamicStores[id] = store;
  }
  function getStore(id: string) {
    const store = dynamicStores[id];
    if (store) {
      return store;
    } else {
      const key = Object.keys(dynamicStores).find(e => {
        const dyn = dynamicStores[e]();
        
        return dyn.getID.value == id;
      })
      return key ? dynamicStores[Number(key)] : undefined;
    }
  }

  return {
    dynamicStores,
    register,
    getStore,
  }
});
