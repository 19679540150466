import useConsoleLogger from "@/utils/useConsoleLogger";
import { defineStore } from "pinia";
interface Toast {
  id: string | number;
  type: "error" | "success" | "warning" | "info";
  message: string;
  showing: boolean;
}
interface State {
  toasts: Toast[];
  currentToast: undefined | Toast;
}
export const useToastStore = defineStore("toasts", {
  // define the state using the State interface
  state: (): State => ({
    toasts: [],
    currentToast: undefined,
  }),
  // define getters, actions, and mutations
  getters: {
    getToasts: (state) => state.toasts,
  },
  actions: {
    addToast(type: "error" | "success" | "warning" | "info", message: string) {
      const id = Date.now();
      const toast = {
        id,
        type,
        message,
        showing: false,
        length: type == "error" ? -1 : type == "warning" ? 600 : 300,
      };
      this.toasts.push(toast);

      if (!this.currentToast) {
        this.showNextToast();
      }
    },

    showNextToast() {
      if (this.toasts.length > 0) {
        this.currentToast = this.toasts.shift();
        if (this.currentToast) {
          this.currentToast.showing = true;
        }
      }
    },

    removeToast(id: string | number) {
      if (this.currentToast) {
        if (this.currentToast.id === id) {
          this.currentToast = undefined;
          this.showNextToast();
        }
      }
    },
  },
});
