/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from "./App.vue";

// Composables
import { createApp } from "vue";
import "./style.css";
// Plugins
import { registerPlugins } from "@/plugins";

const app = createApp(App);

app.directive("select-on-click", {
  mounted(el) {
    const inputElement =
      el.tagName === "INPUT" ? el : el.querySelector("input");
    if (inputElement) {
      inputElement.addEventListener("click", () => {
        console.log("click directive");
        inputElement.select();
      });
    }
  },
});

export function formatNumber(
  number: number | undefined,
  fixed: boolean = false,
  length = 2
) {
  // Detect user's locale from the browser
  const userLocale = navigator.language;

  if (number == undefined) return "";
  let formattedNumber = number;
  if (fixed) {
    const numStr = number.toString();
    const dotIndex = numStr.indexOf(".");

    // Truncate the number to the specified length without rounding
    if (dotIndex !== -1 && length >= 0) {
      const end = Math.min(dotIndex + length + 1, numStr.length);
      formattedNumber = parseFloat(numStr.substring(0, end));
    }
  }
  return new Intl.NumberFormat(userLocale, {
    minimumFractionDigits: fixed ? length : 0, // Ensure two decimal places if fixed is true
  }).format(formattedNumber);
}
app.config.performance = true;
app.config.globalProperties.$formatNumber = formatNumber;

registerPlugins(app);

app.mount("#app");
