import axios, { isCancel, AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { useAuthStore } from "@/store/authStore";
import { useToastStore } from "@/store/toastStore";
import { generateMessageFromError } from "@/utils/errorHandler";
import useConsoleLogger from "@/utils/useConsoleLogger";

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: { "Content-Type": "application/json" },
  
});

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  silentError?: boolean;
}
// Add an interceptor to update the Authorization header before each request
instance.interceptors.request.use((config: CustomAxiosRequestConfig) => {
  const authStore = useAuthStore();
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const config = error.config as CustomAxiosRequestConfig;

    if (!config.silentError) {
      const message = generateMessageFromError(error);
      const toastStore = useToastStore();
      useConsoleLogger.warn("Error axios: ", error);
      toastStore.addToast("error", message);
    }

    // Then throw the error to allow any individual request handlers to catch it as well
    return Promise.reject(error);
  }
);

const noAuthInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

export { instance as axiosInstance, noAuthInstance };
