import {
  shallowRef,
  ref,
  onUnmounted,
  Ref,
  computed,
  ComputedRef,
  onMounted,
} from "vue";
import {
  HubConnection,
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from "@microsoft/signalr";
import {
  PublishAll,
  MarketDisplayItemContract,
  FilterCondition,
  ActiveOrder,
  CompletedOrder,
  MarketDisplayItemPosition,
  Deal,
  UnMatchedDeal,
  MarketDisplayItemConsolidatedPosition,
  MtmViewModel,
  IContractDate,
  DailyTrend,
  MarketDisplayItemInstrument,
} from "@/models/marketData";
import { useAuthStore } from "@/store/authStore";

// import { CustomActiveOrderActions } from "@/store/customActiveOrders";
import { Store } from "pinia";

import { useActiveOrdersStore } from "@/store/activeOrders";
import { useCompletedOrdersStore } from "@/store/completedOrders";
import { useMarketDisplayStore } from "@/store/marketDisplay";
import { BasicStore, CustomAction, createBaseStore } from "@/store/baseStore";
import { CustomActiveOrderActions } from "@/store/customActiveOrders";
import { useToastStore } from "@/store/toastStore";
import useContractTypeFilters from "./useContractTypeFilters";
import useConsoleLogger from "./useConsoleLogger";
import { errorMonitor } from "events";
import { Clearing, ClearingInfo } from "@/models/clearingInfo";
import { SocketResponse } from "@/models/trading";
import { BreakdownAnalysis } from "@/models/breakdownAnalysis";
import { SortTableKey, useGroupingHook } from "./useGrouping";
import { GraphBarResponse } from "./charts/historyProvider";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import {
  SiloCert,
  SiloCertSpotDeal,
  SiloPosition,
  SiloSpotBasisActiveOrder,
  SiloSpotCompletedOrder,
  SiloSpotContractViewModel,
  SiloSpotUnmatchedDeal,
} from "@/models/silos";

// export type CommonStore<T extends WebSocketDataType, U = {}> = ReturnType<
//   typeof createBaseStore<T>
// > &
//   U;
// export type CustomStore<T extends WebSocketDataType, U> = CommonStore<T> & {
//   [K in keyof U]: U[K];
// };
// export type CustomStore<T extends WebSocketDataType, U> = BasicStore<T> & U;

type StoreTypes =
  | typeof useMarketDisplayStore
  | typeof useCompletedOrdersStore
  | typeof useActiveOrdersStore;

type WebSocketDataType =
  | MarketDisplayItemContract
  | MarketDisplayItemInstrument
  | MarketDisplayItemPosition
  | Deal
  | ActiveOrder
  | CompletedOrder
  | UnMatchedDeal
  | MarketDisplayItemConsolidatedPosition
  | MtmViewModel
  | IContractDate
  | BreakdownAnalysis
  | Clearing
  | MtmViewModel
  | GraphBarResponse
  | DailyTrend
  | SiloSpotContractViewModel
  | SiloSpotBasisActiveOrder
  | SiloSpotUnmatchedDeal
  | SiloCertSpotDeal
  | SiloCert
  | SiloSpotCompletedOrder
  | SiloPosition;

type WebSocketHookReturn<T extends WebSocketDataType> = {
  socket: Ref<HubConnection | null>;
  typedArray: <U extends WebSocketDataType>(data: string | object[]) => U[];
  filteredData: ComputedRef<T[]>;
  subscribeToSelected: () => boolean;
  subscribe: (list: string[]) => Promise<boolean>;
};

export interface GroupNode<T> {
  key: string | null;
  value: string | null;
  items: GroupNode<T>[] | T[];
  isOpen: boolean;
  groupKey: keyof T;
}
export type CustomActions<T> = {
  [key: string]: (entity: T) => void;
};

export function createTypedArray<U extends WebSocketDataType>(
  data: string | object[]
): U[] {
  const parsedData = typeof data === "string" ? JSON.parse(data) : data;

  if (!Array.isArray(parsedData)) {
    // throw new Error("Input data must be an array.");
    // parsedData = [parsedData]
    return [createTypedObject<U>(parsedData)];
  }

  return parsedData.map((item) => createTypedObject<U>(item));
}
export function pascalToCamel(str: string): string {
  if (str.toLowerCase() === "contract_type") {
    return "CONTRACT_TYPE";
  }

  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}
function createTypedObject<U extends WebSocketDataType>(
  data: Record<string, unknown>
): U {
  const exampleInstance = {} as U;
  // const typeGuard = getTypeGuard(exampleInstance);
  // if (!typeGuard) {
  //   throw new Error("No type guard found for the expected type");
  // }

  // if (!typeGuard(data)) {
  //   throw new Error("Invalid data structure for the expected type");
  // }

  const typedObject: Partial<U> = {};

  for (const key in data) {
    const camelKey = pascalToCamel(key);
    const value = data[key];
    if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      typedObject[camelKey as keyof U] = value as U[keyof U];
    } else if (typeof value === "object" /* && value !== null */) {
      typedObject[camelKey as keyof U] = createTypedObject(
        value as Record<string, unknown>
      ) as U[keyof U];
    } else {
      throw new Error(`Invalid data for field ${key} ${camelKey} ${value}`);
    }
  }
  return typedObject as U;
}

interface UrlSettings {
  url: string;
  originator: string;
  clearing?: boolean;
}

export function useWebSocket<T extends WebSocketDataType, CustomAction>(
  createStore: ReturnType<typeof createBaseStore<T, CustomAction>>,
  { url, clearing, originator }: UrlSettings,
  sortBy: Ref<SortTableKey<T>[]>,
  filters: Ref<FilterCondition[]>,
  groups: Ref<(keyof T)[]>,
  socketEvents: {
    name: string;
    func: (message: string) => void;
  }[],
  onConnect?: () => Promise<void> | void,
  subscribedToData?: Ref<T[]>
) {
  const authStore = useAuthStore();
  const store = createStore;
  const filtersHook = useContractTypeFilters();
  const { group, sortItems, extractExpandedIDs } = useGroupingHook<T>();
  const subsToAdd = ref<T[]>([]);
  const socket = ref<HubConnection | null>(null) as Ref<HubConnection | null>;
  const subscriptions = ref<T[]>([]);
  const openInstruments = ref(false);

  const currentSubscriptions = computed(() => subscriptions.value);
  const instrumentsToAdd = computed(() => subsToAdd.value);

  const subscribeToSelected = () => {
    try {
      useConsoleLogger.log("WEBSOCKET: Subscribing to : ", instrumentsToAdd);
      subscribe(
        instrumentsToAdd.value.map((e) => {
          if ((e as unknown as any).contract) {
            return (e as unknown as any).contract;
          } else {
            return "";
          }
        })
      );
      instrumentsToAdd.value.forEach((e) => {
        // currentSubscriptions.value.push(e);
      });
      instrumentsToAdd.value.splice(0);
      return true;
    } catch (err) {
      return false;
    }
  };
  const groupedData: ComputedRef<GroupNode<T>[] | T[]> = computed(() => {
    const currentIsOpenStates = extractExpandedIDs(groupedData.value);
    useConsoleLogger.log("subscribedToData", subscribedToData);
    return group(
      subscribedToData
        ? subscribedToData.value != undefined /* &&
          subscribedToData.value.length > 0 */
          ? subscribedToData.value
          : filteredData.value
        : filteredData.value,
      groups?.value || [],
      sortBy.value,
      currentIsOpenStates
    );
  });
  const filteredData: ComputedRef<T[]> = computed(() => {
    const items = store().getData.value;
    const filtered = items.filter((e) => {
      if (!filtersHook.applyConditions(e, filters.value)) {
        return false;
      }
      return e;
    });

    return sortItems(filtered, sortBy.value);
  });

  const disconnect = (endpoint: string) => {
    socket.value?.stop();
  };

  const connect = async (endpoint: string, onSocketConnected: () => void) => {
    socket.value = new HubConnectionBuilder()
      .withUrl(
        `${
          (clearing && clearing == true) || originator == "deals"
            ? import.meta.env.VITE_APP_CLEARING_URL
            : import.meta.env.VITE_APP_API_URL
        }${endpoint}?component=${originator}&access_token=${encodeURIComponent(
          authStore.token!
        )}`,
        { transport: HttpTransportType.WebSockets }
      )
      .configureLogging(
        import.meta.env.MODE == "dev" ? LogLevel.Information : LogLevel.Error
      )
      .withHubProtocol(new MessagePackHubProtocol())
      .withAutomaticReconnect
      /*{
        nextRetryDelayInMilliseconds: (retryContext) => {
          // Customize retry delay logic here
          return Math.random() * 1000; // Example delay
        }  ,

        transports: ["WebSockets", "LongPolling"], // Specify transport priorities ,
      }*/
      ()
      /* .withTransport(HttpTransportType.WebSockets) */
      .build();

    socket.value.on("onconnected", async (message: string) => {
      useConsoleLogger.warn(
        "Socket connected ",
        message,
        socket.value?.connectionId
      );
      if (socket.value) {
        const res = await socket.value?.invoke<SocketResponse>(
          "SubscribeToGroups"
        );
        useConsoleLogger.log("SubscribeToGroups");
        if (onConnect) {
          useConsoleLogger.log("OnConnect exists");
          await onConnect();
        }
      }
    });
    socket.value.on("disconnected", async (message: string) => {
      useConsoleLogger.log(
        "Socket DISCONNECTED ",
        message,
        socket.value?.connectionId
      );
    });

    socket.value.onclose((error?: Error | undefined) => {
      useConsoleLogger.log(
        "Socket Connection Closed ",
        error,
        socket.value?.connectionId
      );
    });
    socket.value.on("MarketInit", (message: string) => {
      useConsoleLogger.log("WEBSOCKET: Market INIT ");
      if (socketEvents.find((e) => e.name !== "MarketInit")) {
        try {
          const temp = createTypedArray<T>(message);
          useConsoleLogger.log("WEBSOCKET: Market Init: ", temp);
          store().setData(temp);
          // store().updateItem(temp);
        } catch (err) {
          useConsoleLogger.error(
            "WEBSOCKET: error parsing OPTION MARKET UPDATE for ",
            message,
            err
          );
        }
      }
    });
    socket.value.on("MarketDisplay", (message: string) => {
      try {
        const temp = createTypedArray<T>(message);
        temp.forEach((e) => {
          store().updateItem(e, "MarketDisplay-usewebsocket");
        });
      } catch (err) {
        useConsoleLogger.error(
          "error parsing OPTION MARKET UPDATE for ",
          message,
          err
        );
      }
    });
    socketEvents.forEach((e) => {
      // socket.value?.off(e.name, e.func);
      socket.value?.on(e.name, e.func);
    });
    // socket.value.on(updateEvent.name, updateEvent.func);

    /* socket.value.on("InfoErrorMessage ", (message: string) => {
      useToastStore().addToast("error", message);
    }); */
    const connected = await socket.value.start().catch((e) => {
      console.error("Error ", e);
      return Promise.reject(e);
    });
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    // if (socket.value) {
    //   const res = await socket.value?.invoke<SocketResponse>(
    //     "SubscribeToGroups"
    //   );
    //   useConsoleLogger.log("SubscribeToGroups");
    //   if (onConnect) {
    //     useConsoleLogger.log("OnConnect exists");
    //     await onConnect();
    //   }
    // }
  };

  function getTypeGuard(obj: any): ((data: any) => boolean) | null {
    // if (isMarketDisplayItemContract(obj)) {
    //   return isMarketDisplayItemContract;
    // }

    // if (isMarketDisplayItemPosition(obj)) {
    //   return isMarketDisplayItemPosition;
    // }

    // Add more type guards for remaining types

    return null;
  }
  async function subscribe(list: string[]) {
    let successful = true;
    // Function to process a single batch of subscriptions
    async function subscribeBatch(batch: string[]): Promise<boolean> {
      if (socket.value) {
        const res = await socket.value.invoke<SocketResponse>(
          "Subscribe",
          batch
        );
        // Optionally handle the response, such as logging or displaying toast notifications
        return res ? res.Status === "Success" : false;
      }
      return false;
    }

    // Function to chunk array into smaller batches
    function chunkArray<T>(array: T[], chunkSize: number): T[][] {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    }

    // Function to process batches with a timeout
    async function processBatchesWithTimeout(
      batches: string[][],
      index: number
    ): Promise<void> {
      console.log("Testing batch ", batches, index);
      if (index < batches.length) {
        const batchSuccessful = await subscribeBatch(batches[index]);
        if (!batchSuccessful) {
          successful = false;
        }
        await new Promise((resolve) => setTimeout(resolve, 0));
        await processBatchesWithTimeout(batches, index + 1);
      }
    }

    const CHUNK_SIZE = 500; // Adjust based on performance tests

    const batches = chunkArray(list, CHUNK_SIZE);

    // Process each batch with timeout
    await processBatchesWithTimeout(batches, 0);

    return successful;
  }

  // async function subscribe(list: string[]) {
  //   let res: any = null;
  //   if (socket.value) {
  //     res = await socket.value.invoke<SocketResponse>("Subscribe", list);
  //   }
  //   // if (res)
  //   // useToastStore().addToast(
  //   //   res.Status == "Success" ? "success" : "warning",
  //   //   res.Message
  //   // );
  //   //return state of subscribe
  //   return res != null ? true : false;
  // }

  async function unSubscribe(list: string[]) {
    let res: any = null;
    if (socket.value) {
      res = await socket.value.invoke<SocketResponse>("Unsubscribe", list);
    }
    // if (res)
    //   useToastStore().addToast(
    //     res.Status == "Success" ? "success" : "warning",
    //     res.Message
    //   );
    //return state of subscribe
    return res != null ? true : false;
  }
  return {
    socket,
    subscribeToSelected,
    filteredData,
    typedArray: createTypedArray,
    subscribe,
    unSubscribe,
    groupedData,
    connect,
    disconnect,
  };
}
