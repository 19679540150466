import { ILoaders, LoaderStatus } from "@/models/UI";
import { computed, onMounted, reactive, ref } from "vue";
import { axiosInstance } from "@/plugins/axios";
import { useAuthStore } from "@/store/authStore";
import { useToastStore } from "@/store/toastStore";
import { useMarketDisplayStore } from "@/store/marketDisplay";
import { useSystemSocket } from "./useSystemSocket";
import { useLayoutStore } from "@/store/layout";
import useConsoleLogger from "./useConsoleLogger";
import { AppInitState, useAppStore } from "@/store/app";
import { generateMessageFromError } from "./errorHandler";
export const useAppInitLoader = (
  loaders: ILoaders,
  appInitState: AppInitState
) => {
  const isOnline = ref(navigator.onLine);

  const authStore = useAuthStore();
  const layoutStore = useLayoutStore();
  const marketStore = useMarketDisplayStore()();
  const { loadMTM, loadContractDates, loadInstruments, loadDailyTrend, loadMarketDataChunks } = useSystemSocket();

  const resetLoaders = () => {
    Object.keys(loaders).forEach((key) => {
      const loader = loaders[key as keyof ILoaders];
      loader.status = LoaderStatus.NotStarted;
      loader.errorMessage = null;
    });
  };

  const initialize = async () => {
    try {
      appInitState.initializing = true;
      if (!axiosInstance) {
        throw new Error("Axios instance not found");
      }
      const delay = new Promise((resolve) => setTimeout(resolve, 2500));
      await delay;
      const promises = [
        loadUserProfile(),
        loadMembers(),
        loadMarketData(),
        loadMTMViaSocket(),
        loadDailyTrendViaSocket(),
        loadContractDatesViaSocket(),
        loadInstrumentsViaSocket(),
        delay,
        // Add other loader functions here...
      ];
      await Promise.allSettled(promises);
      await loadUserLayouts(false);

      let hasError = false;
      for (const loader in loaders) {
        if (loaders[loader as keyof ILoaders].status === LoaderStatus.Error) {
          useConsoleLogger.error(
            `Error loading ${loader}: ${
              loaders[loader as keyof ILoaders].errorMessage
            }`
          );
          hasError = true;
        }
      }

      if (hasError) {
        useConsoleLogger.error("One or more loaders have failed.");
      } else {
        useConsoleLogger.log("All loaders completed successfully.");
      }
      const length =
        layoutStore.isMobile == true
          ? layoutStore.getSavedLayouts.filter(
              (e) => e.userLayoutJson.isMobile == true
            ).length
          : layoutStore.getSavedLayouts.filter(
              (e) => !e.userLayoutJson.isMobile
            ).length;
      if (length <= 1) {
        appInitState.initializing = hasError;
      }
    } catch (err) {
      return Promise.reject(err);
    }
  };
  const reconnect = async () => {
    // Re-initialize the application here
    // Make sure not to overwrite the current layout
    resetLoaders();
    appInitState.initializing = true;
    if (!axiosInstance) {
      throw new Error("Axios instance not found");
    }

    try {
      const delay = new Promise((resolve) => setTimeout(resolve, 2500));
      await delay;
      await Promise.allSettled([
        loadUserProfile(),
        loadMembers(),
        loadMarketData(),
        loadMTMViaSocket(),
        loadDailyTrendViaSocket(),
        loadContractDatesViaSocket(),
        loadInstrumentsViaSocket(),
        delay,
      ]);
      await loadUserLayouts(true);

      let hasError = false;
      for (const loader in loaders) {
        if (loaders[loader as keyof ILoaders].status === LoaderStatus.Error) {
          useConsoleLogger.error(
            `Error loading ${loader}: ${
              loaders[loader as keyof ILoaders].errorMessage
            }`
          );
          hasError = true;
        }
      }

      if (hasError) {
        useConsoleLogger.error("One or more loaders have failed.");
      } else {
        useConsoleLogger.log("All loaders completed successfully.");
      }
      appInitState.initializing = hasError;
      // Handle success
    } catch (error) {
      // Handle errors
      console.error("Error during reconnection:", error);
    }
  };
  async function loadUserProfile() {
    try {
      loaders.userProfile.status = LoaderStatus.Loading;
      await authStore.loadHQAccess();
      const res = await Promise.all([loadUserPref()]);
      loaders.userProfile.status = LoaderStatus.Success;
      return Promise.resolve();
    } catch (err) {
      loaders.userProfile.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message = msg || "An error occurred while loading user profile.";
      loaders.userProfile.errorMessage = message;
      return Promise.reject(err);
    }
  }
  async function loadContractDatesViaSocket() {
    try {
      loaders.contractDate.status = LoaderStatus.Loading;
      await loadContractDates();
      loaders.contractDate.status = LoaderStatus.Success;
    } catch (err) {
      loaders.contractDate.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message = msg || "Error Loading Contract Dates:" + err;
      loaders.contractDate.errorMessage = message;
    }
  }
  async function loadInstrumentsViaSocket() {
    try {
      loaders.instruments.status = LoaderStatus.Loading;
      await loadInstruments();
      loaders.instruments.status = LoaderStatus.Success;
    } catch (err) {
      loaders.instruments.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message = msg || "Error Loading Instruments:" + err;
      loaders.instruments.errorMessage = message;
    }
  }
  async function loadDailyTrendViaSocket() {
    try {
      loaders.dailyTrend.status = LoaderStatus.Loading;
      await loadDailyTrend();
      loaders.dailyTrend.status = LoaderStatus.Success;
    } catch (err) {
      loaders.dailyTrend.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message = msg || "Error Loading Trade history:" + err;
      loaders.dailyTrend.errorMessage = message;
    }
  }
  async function loadMTMViaSocket() {
    try {
      loaders.mtm.status = LoaderStatus.Loading;
      await loadMTM();
      loaders.mtm.status = LoaderStatus.Success;
    } catch (err) {
      loaders.mtm.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message = msg || "Error Loading MTM:" + err;
      loaders.mtm.errorMessage = message;
    }
  }
  async function loadUserLayouts(reconnect: boolean) {
    try {
      loaders.layout.status = LoaderStatus.Loading;
      await layoutStore.loadUserLayoutAPI();
      /// check localstorage

      if (!reconnect) {
        const prevLayout = localStorage.getItem("lastUsedLayout");
        //
        if (
          (layoutStore.getSavedLayouts.filter((e) => !e.userLayoutJson.isMobile)
            .length == 0 &&
            layoutStore.isMobile == false) ||
          (layoutStore.getSavedLayouts.filter(
            (e) => e.userLayoutJson.isMobile == true
          ).length == 0 &&
            layoutStore.isMobile == true)
        ) {
          await layoutStore.setDefaultLayout();
        } else if (prevLayout && layoutStore.isMobile == false) {
          const found = layoutStore.getSavedLayouts.find(
            (e) => !e.userLayoutJson.isMobile && e._id == Number(prevLayout)
          );
          if (found) {
            layoutStore.setCurrentLayout(found);
          }
        } else if (
          layoutStore.getSavedLayouts.filter((e) => !e.userLayoutJson.isMobile)
            .length == 1 &&
          layoutStore.isMobile == false
        ) {
          const found = layoutStore.getSavedLayouts.find(
            (e) => !e.userLayoutJson.isMobile
          );
          if (found) layoutStore.setCurrentLayout(found);
        } else if (
          layoutStore.getSavedLayouts.filter(
            (e) => e.userLayoutJson.isMobile == true
          ).length == 1 &&
          layoutStore.isMobile == true
        ) {
          const existing = layoutStore.getSavedLayouts.find(
            (e) =>
              e.userLayoutJson.isMobile == true &&
              e.userLayoutJson.name.toLowerCase().includes("futures")
          );
          layoutStore.setCurrentLayout(
            existing || layoutStore.mobileLayoutOptions[0]
          );
        }
      }

      loaders.layout.status = LoaderStatus.Success;
    } catch (err) {
      loaders.layout.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message =
        msg || "An error occurred while setting up user preferences.";
      loaders.layout.errorMessage = message;
    }
  }
  async function loadUserPref() {
    try {
      loaders.userPref.status = LoaderStatus.Loading;

      // call GET api for user pref
      await authStore.loadUserPreference();
      if (authStore.getUserPref != null)
        loaders.userPref.status = LoaderStatus.Success;
      else {
        loaders.userPref.status = LoaderStatus.Error;
        if (loaders.userPref.errorMessage == null) {
          loaders.userPref.errorMessage = "No userpref returned";
        }
      }
      return Promise.resolve();
    } catch (err) {
      loaders.userPref.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message =
        msg || "An error occurred while setting up user preferences.";
      loaders.userPref.errorMessage = message;
      // return Promise.reject(err);
    }
  }
  async function loadMembers() {
    try {
      loaders.members.status = LoaderStatus.Loading;
      const res = await authStore.loadMembers();
      loaders.members.status = LoaderStatus.Success;
      return Promise.resolve(res);
    } catch (err) {
      loaders.members.status = LoaderStatus.Error;
      const msg = generateMessageFromError(err);
      const message =
        msg || "An error occurred while setting up user preferences.";
      loaders.members.errorMessage = message;
      return Promise.reject(err);
    }
  }
  async function loadMarketData() {
    try {
      loaders.marketData.status = LoaderStatus.Loading;
      const res = await loadMarketDataChunks();
      // const res =
      //   await marketStore.customActions.customActions?.loadMarketDisplay();
      loaders.marketData.status = LoaderStatus.Success;
      return res;
    } catch (err) {
      loaders.marketData.status = LoaderStatus.Error;

      const msg = generateMessageFromError(err);
      const message = msg || "An error occurred while getting market data.";
      loaders.marketData.errorMessage = message;
      return Promise.reject(err);
    }
  }
  window.addEventListener("online", () => {
    isOnline.value = true;
    reconnect(); // Call the reconnect function when back online
  });

  // Event listener for offline status
  window.addEventListener("offline", () => {
    isOnline.value = false;
    // Handle offline status, maybe pause certain operations
  });

  return {
    initialize,
  };
};
