import { ref } from "vue";
import { GroupNode } from "./useWebsocket";
import { isGroupNode } from "./NestedRows";
import { de } from "vuetify/lib/locale/index.mjs";
import useConsoleLogger from "./useConsoleLogger";

export interface SortTableKey<T extends object> {
  key: keyof T;
  order?: boolean | "asc" | "desc";
}
export interface Sort {
  key: string;
  order?: boolean | "asc" | "desc";
}

export function useGroupingHook<T extends object>() {
  const monthOrder = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const customOrder = ["WMAZ", "YMAZ", "CORN", "WEAT", "SUNS", "SOYA"];
  const isOpenStates = ref<Record<string, boolean>>({});
  function group(
    items: T[],
    groupBy: (keyof T)[],
    sortBy: SortTableKey<T>[],
    isOpenStates: Record<string, any>,
    parentKey: string = ""
  ): GroupNode<T>[] | T[] {
    useConsoleLogger.log(
      "items",
      items,
      "groupBy",
      groupBy,
      "sortBy",
      sortBy,
      "isOpenStates",
      isOpenStates,
      "parentKey",
      parentKey
    );
    if (groupBy.length === 0) {
      return items; // No more grouping required, return the array as is
    }
    items = sortItems(items, sortBy);
    const result: GroupNode<T>[] = [];
    const temp = groupBy[0].toString().includes(".");
    const gValue = groupBy[0].toString();
    const fieldPath = temp == true ? gValue.split(".") : [gValue];

    const grouped = items.reduce((grouped: Record<string, T[]>, item: T) => {
      let fieldValue: any = item;
      for (const keyPart of fieldPath) {
        fieldValue = fieldValue[keyPart];
        if (fieldValue === undefined) {
          break;
        }
      }

      // const key = fieldValue?.toString() || "default";
      const key =
        (parentKey ? parentKey + "|" : "") +
        (fieldValue?.toString() || "default");

      if (!grouped[key]) {
        grouped[key] = [];
      }

      grouped[key].push(item);

      return grouped;
    }, {});

    for (const key in grouped) {
      let obj: any = grouped[key][0];
      for (const keyPart of fieldPath) {
        if (obj[keyPart] !== undefined) {
          obj = obj[keyPart];
        } else {
          obj = undefined;
          break;
        }
      }

      const fieldValue = obj?.toString() || null;
      const currentIsOpen =
        isOpenStates[key] !== undefined ? isOpenStates[key] : false;
      if (groupBy.length === 1) {
        // If this is the last level of grouping, add the items directly
        // const groupKey = fieldPath.pop() as keyof T;
        const groupKey = fieldPath[fieldPath.length - 1] as keyof T;
        result.push({
          key: key,
          value: fieldValue,
          items: sortItems(grouped[key], sortBy),
          isOpen: currentIsOpen,
          groupKey: groupKey,
        });
      } else {
        let items = group(
          grouped[key],
          groupBy.slice(1),
          sortBy,
          isOpenStates,
          key
        );
        // Check if items are of type T[] and sort if needed
        if (
          Array.isArray(items) &&
          items.length > 0 &&
          !isGroupNode(items[0])
        ) {
          items = sortItems(items as T[], sortBy);
        } else {
          for (let groupItem of items as GroupNode<T>[]) {
            // Sort group items if they are T[]
            if (!isGroupNode(groupItem.items[0])) {
              groupItem.items = sortItems(groupItem.items as T[], sortBy);
            }
          }
        }
        result.push({
          key: key,
          value: fieldValue,
          items: Array.isArray(items) ? items : [items], // ensure items is an array
          isOpen: currentIsOpen,
          groupKey: fieldPath[fieldPath.length - 1] as keyof T,
          // groupKey: fieldPath.pop() as keyof T,
        });
      }
    }
    if (
      result[0] &&
      result[0].groupKey != "instrument" &&
      result[0].groupKey != "contractDisplay" &&
      result[0].groupKey != "contractDate"
    ) {
      result.sort((a, b) => {
        if (a.key == null || b.key == null) return 0;
        if (a.key < b.key) {
          return -1;
        }
        if (a.key > b.key) {
          return 1;
        }
        return 0;
      });
    } else if (result[0] && result[0].groupKey == "contractDate") {
      result.sort((a, b) => {
        const res = sortByContractDate(a.key as keyof T, b.key as keyof T);
        return res;
      });
    }
    const hasResult = result.length > 0;
    return hasResult ? result : items; // If result is empty, return the original array
  }
  // function sortedGroup(
  //   items: T[],
  //   groupBy: (keyof T)[],
  //   sortBy: SortTableKey<T>[],
  //   isOpenStates: Record<string, any>,
  //   parentKey: string = ""
  // ) {
  //   const groupRes = group(items, groupBy, sortBy, isOpenStates, parentKey);

  //   const isGroup = isGroupNode(groupRes);
  //   if (isGroup == true) {
  //     const g = groupRes as GroupNode<T>[];
  //     return groupRes.sort((a, b) => {
  //       // const aInstrFlat = a["instrument" as keyof T];
  //       // const bInstrFlat = b["instrument" as keyof T];

  //       return 0;
  //     });
  //   }
  //   return groupRes;
  // }
  function inCustomOrder(value: string): boolean {
    return customOrder.indexOf(value) !== -1;
  }
  function getMonthAndYear(contractDate: string): {
    month: string;
    year: number;
  } {
    if (!contractDate) {
      debugger;
    }
    const month = contractDate.slice(0, 3);
    const year = parseInt(contractDate.slice(3));
    return { month, year };
  }
  function getNestedValue(obj: any, path: string): any {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  }
  function sortByInstrument(a: keyof T, b: keyof T): number {
    const aInCustom = inCustomOrder(a as string);
    const bInCustom = inCustomOrder(b as string);

    if (aInCustom && bInCustom) {
      const orderA = customOrder.indexOf(a as string);
      const orderB = customOrder.indexOf(b as string);

      return orderA - orderB;
    } else if (aInCustom) {
      return -1;
    } else if (bInCustom) {
      return 1;
    } else {
      // if (!a) debugger;
      return (a as string).localeCompare(b as string);
    }
  }
  function sortByContractDate(
    a: keyof T,
    b: keyof T,
    order?: "asc" | "desc" | boolean
  ): number {
    // const aValue = getNestedValue(a, key as string);
    // const bValue = getNestedValue(b, key as string);
    if (!b || !a) {
      debugger;
    }
    const aMY = getMonthAndYear(a as string);
    const bMY = getMonthAndYear(b as string);

    if (aMY.year < bMY.year)
      return order === "asc" || order === true || !order ? -1 : 1;
    if (aMY.year > bMY.year)
      return order === "asc" || order === true || !order ? 1 : -1;

    const aMonthIndex = monthOrder.indexOf(aMY.month);
    const bMonthIndex = monthOrder.indexOf(bMY.month);

    if (aMonthIndex < bMonthIndex)
      return order === "asc" || order == true || !order ? -1 : 1;
    if (aMonthIndex > bMonthIndex)
      return order === "asc" || order == true || !order ? 1 : -1;

    return 0; // Default case
  }
  function sortItems(items: T[], sortBy: SortTableKey<T>[]): T[] {
    return items.sort((a, b) => {
      // If contract display
      const aInstr = getNestedValue(a, "contractDisplay.instrument");
      const bInstr = getNestedValue(b, "contractDisplay.instrument");
      // if break down
      const aInstrFlat = a["instrument" as keyof T];
      const bInstrFlat = b["instrument" as keyof T];
      // TODO confirm this return is correct
      if (!aInstr && !aInstrFlat) return 0;
      const result = sortByInstrument(
        aInstr || aInstrFlat,
        bInstr || bInstrFlat
      );
      if (result !== 0) return result;

      for (const sortObj of sortBy) {
        const { key, order } = sortObj;
        if ((key as string).endsWith("contractDate")) {
          const aValue = getNestedValue(a, key as string);
          const bValue = getNestedValue(b, key as string);
          const result = sortByContractDate(aValue, bValue, order);
          if (result !== 0) return result;
        } else if ((key as string).endsWith("instrument")) {
          continue;
        } else if ((key as string).endsWith("strike")) {
          const aValue = getNestedValue(a, key as string);
          const bValue = getNestedValue(b, key as string);
          let val = 0;
          if (order === "desc") {
            val = bValue - aValue;
          } else {
            val = aValue - bValue;
          }
          // if (val == 0) {
          //   // sort by flag
          //   const aFlag = (a as any).contractDisplay.flag || "";
          //   const bFlag = (b as any).contractDisplay.flag || "";
          //   return (aFlag).localeCompare(bFlag)
          // }
          return val;
        } else {
          if (a[key] < b[key]) return order === "asc" ? -1 : 1;
          if (a[key] > b[key]) return order === "asc" ? 1 : -1;
          // if (a[key] < b[key]) return order === "desc" ? 1 : -1;
          // if (a[key] > b[key]) return order === "desc" ? -1 : 1;
        }
      }
      return 0; // all sorting criteria matched equally
    });
  }
  function extractExpandedIDs(
    grouped: GroupNode<T>[] | T[],
    expandedIDs: Record<string, any> = {}
  ): Record<string, boolean> {
    if (Array.isArray(grouped)) {
      grouped.forEach((group) => {
        if ("key" in group) {
          expandedIDs[String(group.key)] =
            isOpenStates.value[group.key as string] ?? group.isOpen;

          // Continue the recursive extraction for nested items
          if (Array.isArray(group.items)) {
            extractExpandedIDs(group.items, expandedIDs);
          }
        }
      });
    }
    return expandedIDs;
  }
  function setRowOpenState(id: string, value: boolean) {
    isOpenStates.value[id] = value;
  }

  return {
    group,
    sortItems,
    sortByContractDate,
    sortByInstrument,
    extractExpandedIDs,
    isOpenStates,
    setRowOpenState,
    // sortedGroup,
  };
}
