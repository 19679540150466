import { Ref } from "vue";
import { LAYOUT } from "./layout";
import { Capacity } from "./trading";
import { ComponentRegistry } from "./componentRegistry";

export interface ActionButton {
  id: string;
  tooltip: string;
  color: string;
  variant: VariantButton;
  disabled?: boolean;
  density: string;
  icon: string;
  textField: TextField | null; // this is null if there's no text field
  action: () => void;
}
export type VariantButton = NonNullable<
  "flat" | "text" | "elevated" | "tonal" | "outlined" | "plain"
>;
export type VariantTextField =
  | "filled"
  | "outlined"
  | "plain"
  | "underlined"
  | "solo"
  | "solo-inverted"
  | "solo-filled";

export type Density = null | "default" | "comfortable" | "compact";
export interface TextField {
  density: Density;
  variant: VariantTextField;
  singleLine: boolean;
  label: string;
  hideDetails: boolean | "auto" | undefined;
  placeholder: string;
  type: string;
}
export interface IUserFlags {
  insertOrderOrDouble: boolean;
  cancelAllActiveOrders: boolean;
  suspendAllActiveOrders: boolean;
  deleteActiveOrder: boolean;
  suspendActiveOrder: boolean;
  reduceActiveOrder: boolean;
  resubmitActiveOrder: boolean;
  editSuspendOrder: boolean;
  insertUnmatchedDeal: boolean;
  deleteUnmatchedDeal: boolean;
  acceptUnmatchedDeal: boolean;
  editUnmatchedDeal: boolean;
  splitDeal: boolean;
  assignDealToMember: boolean;
  tripartiteDeal: boolean;
  cumulateDeal: boolean;
  correctDealPrinciple: boolean;
  exerciseOption: boolean;
  abandonOption: boolean;
  subAccountChange: boolean;
  deleteSiloCertAuctionBid: boolean;
}
export interface IUserDefaults {
  priceChangeMop: number;
  rateChangeMop: number;
  volChangeMop: number;
  maxOrderQuantity: number;
  defaultOrderQuantity: number;
  defaultPopupNotificationTime: number;
  timeout: number;
  maxDepth: number;
  capacity: Capacity;
  soundOnDealExecution: boolean;
  colorOnUpdates: "td" | "text";
  tradeFormLocation: "last" | "button";
  compact: boolean;
}
export interface IUserProfileDefaults {
  branch: string;
  dealer: string;
  client: string;
}
export interface IUserSubAccountDefaults {
  defaultSubAcc: string;
  userSubAccounts: string[];
}
export interface IUserPref {
  flags: IUserFlags;
  profile: IUserProfileDefaults;
  defaults: IUserDefaults;
  // subAccounts: IUserSubAccountDefaults;
}
export interface IUserPrefBeforeConvert {
  flags: IUserFlags | null;
  profile: IUserProfileDefaults | null;
  defaults: IUserDefaults | null;
  // subAccounts: IUserSubAccountDefaults | null;
}
export interface UserPreferencesBeforeConvert extends IUserPrefBeforeConvert {
  _id?: number;
  userId: string;
  userPreferenceJson: IUserPref | null;
}
export interface UserPreferences extends IUserPref {
  _id?: number;
  userId: string;
  userPreferenceJson: IUserPref | null;
}
export interface UserLayout {
  _id?: number;
  // userId: string;
  userLayoutJson: LAYOUT;
}

export interface FormRef {
  [key: string]: any;
  branch: string | null;
  dealer: string | null;
  principal: string | null;
}

export interface MenuItem {
  id: string;
  name: string;
  props?: PossiblePropTypes;
  component?: keyof ComponentRegistry | null;
  items?: MenuItem[];
  icon?: string;
  show?: boolean;
}

export interface PossiblePropTypes {
  id?: string; // item identification
  displaySeq?: number; // item identification
  contractName?: string;
  siloContractTitle?: string;
  // twoDecimals?: boolean; //depth
}
interface LoaderText {
  [LoaderStatus.Error]: string;
  [LoaderStatus.Success]: string;
  [LoaderStatus.NotStarted]: string;
  [LoaderStatus.Loading]: string;
}
export enum LoaderStatus {
  Loading = "loading",
  NotStarted = "notStarted",
  Success = "success",
  Error = "error",
}
export interface ILoader {
  text: LoaderText;
  status: LoaderStatus;
  errorMessage: null | string;
}
export const loadersTypes = [
  "userProfile",
  "marketData",
  "members",
  "layout",
  "userPref",
  "mtm",
  "contractDate",
  "instruments",
  "dailyTrend",
] as const;
export type LoaderType = (typeof loadersTypes)[number];
export type ILoaders = {
  [K in LoaderType]: ILoader;
};
