// Utilities
import { defineStore } from "pinia";
import {
  FilterCondition,
  MarketDisplayItemContract as MainModel,
  MarketDisplayItemContract,
} from "@/models/marketData";
// import { createBaseStore, MyActions } from "./baseStore";
import { getMarketDisplay } from "@/utils/api";
import { computed, ComputedRef, ref, Ref } from "vue";
import { createBaseStore, MyStoreActions } from "./baseStore";
import useContractTypeFilters from "@/utils/useContractTypeFilters";
import useConsoleLogger from "@/utils/useConsoleLogger";
// import { CustomStore } from "@/utils/useWebsocket";

type LANGUAGE = "en" | "afr";
type THEME = "light" | "dark";

export interface MarketDisplayStoreActions extends MyStoreActions<MainModel> {
  loadMarketDisplay: typeof loadMarket;
}

export const useMarketDisplayStore = () => {
  const store = createBaseStore<MainModel, MarketDisplayStoreActions>(
    "marketDisplay",
    "contract",
    undefined,
    
    // {
    //   loadMarketDisplay: loadMarket,
    // }
  );
  return store;
  // const customActions: MarketDisplayStoreActions = {
  //   loadMarketDisplay: loadMarket,
  // };
  // return {
  //   ...customActions,
  //   ...store(),
  // };
};
// async function loadMarket() {
//   const store = useMarketDisplayStore();
//   const res = await getMarketDisplay();
//   console.log("Received iiner market data");
//   store().setData(res);
//   // res.forEach((e) => {
//   //   console.log("Updating store")
//   //   updateStore(e);
//   // });
//   return Promise.resolve();
// }

// function updateStore(updatedItem: MainModel) {
//   const store = useMarketDisplayStore();
//   const found = store().getData.value.findIndex(
//     (e) => updatedItem.contract == e.contract
//   );
//   if (found == -1) {
//     store().data.push(updatedItem);
//   } else {
//     store().deepMerge(store().data[found], updatedItem);
//   }
// }

// async function loadMarket() {
//   const store = useMarketDisplayStore();
//   const res = await getMarketDisplay();
//   console.log("Received inner market data");
//   store().setData(res);
//   // const updates = new Map<string, MainModel>();
//   // res.forEach((item) => {
//   //   updates.set(item.contract, item);
//   // });

//   // batchUpdateStore(updates);
//   return Promise.resolve();
// }

// function batchUpdateStore(updates: Map<string, MainModel>) {
//   const store = useMarketDisplayStore();
//   const data = store().getData.value;

//   updates.forEach((updatedItem, contract) => {
//     const foundIndex = data.findIndex((item) => item.contract === contract);
//     if (foundIndex === -1) {
//       console.log("Pushing")
//       store().data.push(updatedItem);
//     } else {
//       console.log("Deep merge ")
//       store().deepMerge(data[foundIndex], updatedItem);
//     }
//   });
// }

async function loadMarket() {
  // const store = useMarketDisplayStore();
  // const res = await getMarketDisplay();
  // console.log("Received inner market data");

  // const updatesMap = new Map<string, MainModel>();
  // const newItems: MainModel[] = [];

  // // Separate updates and new items
  // res.forEach((item) => {
  //   const existsInStore = store().getData.value.some(
  //     (e) => e.contract === item.contract
  //   );
  //   if (existsInStore) {
  //     updatesMap.set(item.contract, item);
  //   } else {
  //     newItems.push(item);
  //   }
  // });

  // applyUpdates(updatesMap);
  // store().data = [...store().data, ...newItems];

  return Promise.reject("Not implemented");
}

function applyUpdates(updatesMap: Map<string, MainModel>) {
  return Promise.reject("Not implemented");
  // const store = useMarketDisplayStore();

  // store().getData.value.forEach((item, index) => {
  //   if (updatesMap.has(item.contract)) {
  //     store().deepMerge(store().data[index], updatesMap.get(item.contract));
  //   }
  // });
}
